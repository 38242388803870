import React from 'react';
import ReactDOM from 'react-dom';
import { XMarkIcon } from '@heroicons/react/24/outline';
import './styles/index.scss';

interface Props {
  content: React.ReactNode;
  contentTitle: React.ReactNode;
  closeModal: () => void;
  modalClass: string;
}

export default class ModalContent extends React.Component<Props> {
  public render() {
    const { content, closeModal, contentTitle, modalClass } = this.props;

    return ReactDOM.createPortal(
      <div
        className="bg-[rgba(0,0,0,0.6)] h-full fixed z-[100] w-full left-0 top-0"
        onClick={closeModal}
      >
        <div
          className={`modal-global ${modalClass}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="mx-auto md:m-5 mt-5 p-3 w-auto overscroll-none">
            <div className="flex justify-between items-center mb-5">
              <h4>{contentTitle}</h4>
              <div className="sticky right-2" onClick={closeModal}>
                <XMarkIcon
                  className="h-6 w-6 hover:cursor-pointer"
                  aria-hidden="true"
                />
              </div>
            </div>
            {content}
          </div>
        </div>
      </div>,
      document.body,
    );
  }
}
