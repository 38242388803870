import React from 'react';
import SingleBanner from './SingleBanner';

const BannersLayout = () => {
  return (
    <div className="flex flex-col lg:flex-row pt-0 lg:pt-2.5">
      <div className="w-full lg:w-1/2 pr-0 lg:pr-2.5 aspect-banner">
        <SingleBanner slot="home-left-big" size="big" />
      </div>
      <div className="flex w-full lg:w-1/2 pt-5 lg:pt-0 pl-0 lg:pl-2.5 overflow-x-auto snap-x snap-mandatory">
        <div
          className={`w-3/4 sm:w-1/2 shrink-0 sm:shrink-1 snap-center pr-2.5`}
        >
          <SingleBanner slot="home-middle-small" size="small" />
        </div>
        <div
          className={`w-3/4 sm:w-1/2 shrink-0 sm:shrink-1 snap-center pl-2.5`}
        >
          <SingleBanner slot="home-right-small" size="small" />
        </div>
      </div>
    </div>
  );
};

export default BannersLayout;
