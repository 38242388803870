import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import LocalizedLink from '../LocalizedLink';
import Image from '../Image';
import { Sizes } from '../../constants/Image';
import { Banner } from './SingleBanner';

enum BannerType {
  STATIC = 'static-image',
  MULTI_BUTTON = 'multi-button',
}

enum ButtonGroup {
  IMAGE = 'image',
  BRAND = 'brand',
  GENDER = 'gender',
  LINK = 'link',
}

type Props = {
  banner: Banner;
};

const BannerDesign = ({ banner }: Props) => {
  const [bannerImageExposed, setBannerImageExposed] = useState(true);

  if (banner.bannerType === BannerType.STATIC) {
    const bannerLink = banner.buttons.find(
      (button) => button.buttonGroup === ButtonGroup.IMAGE,
    );

    return (
      <LocalizedLink
        className="block h-full"
        to={bannerLink?.url || window.location.href}
      >
        <Image
          className="bg-neutral-200 rounded-lg object-cover h-full w-full"
          image={banner.image}
          defaultSize={Sizes.W435}
          width="435"
          height="717"
          allSizes={[Sizes.W435, Sizes.W540, Sizes.W890, Sizes.W1600]}
          sizes={['(max-width: 1024px) 90vw, 50vw']}
          alt={'Rademar'}
        />
      </LocalizedLink>
    );
  }

  if (bannerImageExposed) {
    return (
      <button
        className="block h-full w-full"
        onClick={() => {
          setBannerImageExposed(false);
        }}
      >
        <Image
          className="bg-neutral-200 rounded-lg object-cover h-full w-full"
          image={banner.image}
          defaultSize={Sizes.W890}
          width="890"
          height="718"
          allSizes={[Sizes.W540, Sizes.W890, Sizes.W1600]}
          sizes={['(max-width: 1024px) 90vw, 50vw']}
          alt={'Rademar'}
        />
      </button>
    );
  }

  const brandButtons = banner.buttons.filter(
    (button) => button.buttonGroup === ButtonGroup.BRAND,
  );

  const categoryButtons = banner.buttons.filter(
    (button) => button.buttonGroup === ButtonGroup.GENDER,
  );

  const linkButtons = banner.buttons.filter(
    (button) => button.buttonGroup === ButtonGroup.LINK,
  );

  return (
    <div
      className="flex flex-wrap text-sm flex-shrink-0 rounded-lg border border-rgrey-light h-full"
      onClick={() => setBannerImageExposed(true)}
    >
      <button
        className="flex mt-7 mb-3"
        onClick={() => setBannerImageExposed(true)}
      >
        <ChevronLeftIcon
          aria-hidden
          className="ml-12 mr-2 h-6 self-center"
        ></ChevronLeftIcon>
        <div className="items-left self-center">Tagasi</div>
      </button>

      <div className="w-full mx-12 mb-0">
        {categoryButtons.map((button) => (
          <LocalizedLink
            key={button.url}
            to={button.url}
            className="flex justify-between my-3 w-full py-3 pl-4 border rounded border-rgrey-light hover:bg-rgrey-light"
          >
            <div className="items-left self-center ">{button.title}</div>
            <ChevronRightIcon
              aria-hidden
              className="mr-3 h-6"
            ></ChevronRightIcon>
          </LocalizedLink>
        ))}

        <div className="flex flex-wrap content-center mt-5 -m-2">
          {brandButtons.map((button) => (
            <div className="p-2 w-1/2 md:w-1/4 xl:w-1/2" key={button.url}>
              <LocalizedLink to={button.url} label={button.title}>
                <div className="group border rounded border-rgrey-light bg-white h-20 px-2 py-5 flex items-center justify-center hover:bg-black">
                  <img
                    className="w-full h-auto max-w-[120px] max-h-[40px] group-hover:invert"
                    src={button.brand?.logoUrl}
                    alt={button.title}
                  />
                </div>
              </LocalizedLink>
            </div>
          ))}
        </div>
      </div>

      <div className="flex my-8 w-full justify-center">
        <LocalizedLink to={linkButtons[0].url} className="flex">
          <div className="font-axi-bold self-center ml-6">Kõik üleriided</div>
          <ChevronRightIcon
            aria-hidden
            className="mr-3 h-6 self-center"
          ></ChevronRightIcon>
        </LocalizedLink>
      </div>
    </div>
  );
};

export default BannerDesign;
