import React from 'react';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import Translate from '../Translate/Translate';
import useStores from '../../hooks/useStores';
import { isLvDomain } from '../../services/Env';
import { Language } from '../../stores/Translations';

const LanguageSelect = () => {
  const { translations } = useStores();
  const navigate = useNavigate();
  const location = useLocation();

  const currentLanguage = translations.language;
  const languageOptions = [Language.ET, Language.RU];
  if (isLvDomain()) {
    languageOptions.push(Language.LV);
  }

  const changeLanguage = (newLanguage: Language) => {
    translations.setLanguage(newLanguage);

    let newPath = location.pathname;
    if (!translations.isDefaultLanguage(newLanguage)) {
      // Clean up path of previous language values
      const splitPath = newPath.split('/');
      const allLanguages = translations.getAvailableLanguages();
      newPath = splitPath
        .filter((pathItem) => !allLanguages.includes(pathItem))
        .join('/');

      // Add new language to path
      newPath = `/${newLanguage}${newPath}`;
    } else {
      if (translations.isValidLanguage(newLanguage)) {
        const splitPath = newPath.split('/');
        splitPath.splice(1, 1);
        newPath = splitPath.join('/');
      }
    }

    navigate({
      pathname: newPath || '/',
      search: window.location.search,
    });

    window.location.reload();
  };

  return (
    <Menu>
      {({ open }) => (
        <>
          <Menu.Button>
            <div
              className={classNames(
                'font-axi-bold px-3 h-10 w-24 flex justify-between items-center border border-neutral-200',
                {
                  'rounded-t': open,
                  rounded: !open,
                },
              )}
            >
              <Translate
                component="language"
                keyword={`language-${currentLanguage}`}
              />
              <ChevronDownIcon aria-hidden className="ml-4 h-6" />
            </div>
          </Menu.Button>
          <Menu.Items className="absolute w-24 origin-top-right divide-y divide-neutral-200 rounded-b bg-white shadow-lg border-l border-r border-b border-neutral-200 focus:outline-none">
            {languageOptions.map((languageOption, index) => (
              <div key={languageOption}>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        'font-axi-bold w-full flex px-3 h-10 items-center',
                        {
                          'bg-neutral-200': active,
                          'rounded-b': index === languageOptions.length - 1,
                        },
                      )}
                      onClick={() => changeLanguage(languageOption)}
                    >
                      <Translate
                        component="language"
                        keyword={`language-${languageOption}`}
                      />
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export default LanguageSelect;
