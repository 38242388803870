import Ajax from '../services/Ajax';

export default class Newsletter {
  async postEmail(email) {
    const user_email = await Ajax.post('newsletter/subscribe', {
      email: email,
    });

    return user_email;
  }
}
