import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';
import AuthenticationStore from '../../stores/Authentication';
import Modal from 'react-modal';
import Close from '../../images/close.svg';

import LoginForm from '../Forms/Login';
import RegisterForm from '../Forms/Register';

import Translate from '../Translate/Translate';
import LocalizedLink from '../LocalizedLink';

interface Props {
  authentication?: AuthenticationStore;
  buttonText?: boolean;
  closeMobileMenu?: () => void;
}

@inject('authentication')
@observer
class ProfileButton extends React.Component<Props> {
  @observable modalOpen = false;
  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  @action toggleModal() {
    this.modalOpen = !this.modalOpen;
  }

  profileButton() {
    const { authentication, closeMobileMenu } = this.props;

    let button = (
      <div
        className="flex flex-col justify-center items-center border rounded text-center cursor-pointer p-4 lg:px-3.5 lg:py-2.5 border-solid border-rgrey-light lg:flex-row-reverse w-full"
        onClick={this.toggleModal}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.935"
          height="20"
          viewBox="0 0 19.935 20"
        >
          <path
            d="M406.572,769.82a9.992,9.992,0,0,0-3.8-2.383,5.782,5.782,0,1,0-6.542,0,10.022,10.022,0,0,0-6.694,8.588.787.787,0,0,0,.774.867.774.774,0,0,0,.782-.691,8.437,8.437,0,0,1,16.818,0,.774.774,0,0,0,.782.691.786.786,0,0,0,.773-.866A9.94,9.94,0,0,0,406.572,769.82Zm-7.071-2.929a4.219,4.219,0,1,1,4.219-4.218A4.222,4.222,0,0,1,399.5,766.891Z"
            transform="translate(-389.533 -756.891)"
          />
        </svg>
        <p className="uppercase pt-3 lg:pt-0 lg:pr-4 font-axi-bold text-center lg:whitespace-nowrap">
          <Translate component="login" keyword="login-button" />
        </p>
      </div>
    );

    if (authentication.initialized && authentication.loggedIn) {
      button = (
        <LocalizedLink
          to="/toolaud"
          onClick={closeMobileMenu}
          className="flex flex-col justify-center items-center rounded text-center cursor-pointer p-4 lg:px-3.5 lg:py-2.5 text-white border border-solid border-black bg-black fill-white lg:flex-row-reverse w-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.935"
            height="20"
            viewBox="0 0 19.935 20"
          >
            <path
              d="M406.572,769.82a9.992,9.992,0,0,0-3.8-2.383,5.782,5.782,0,1,0-6.542,0,10.022,10.022,0,0,0-6.694,8.588.787.787,0,0,0,.774.867.774.774,0,0,0,.782-.691,8.437,8.437,0,0,1,16.818,0,.774.774,0,0,0,.782.691.786.786,0,0,0,.773-.866A9.94,9.94,0,0,0,406.572,769.82Zm-7.071-2.929a4.219,4.219,0,1,1,4.219-4.218A4.222,4.222,0,0,1,399.5,766.891Z"
              transform="translate(-389.533 -756.891)"
            />
          </svg>
          <p className="uppercase pt-3 lg:pt-0 lg:pr-4 font-axi-bold text-center lg:whitespace-nowrap">
            <Translate component="mobile-menu" keyword="login" />
          </p>
        </LocalizedLink>
      );
    }

    return button;
  }

  render() {
    return (
      <div className="flex">
        {this.profileButton()}
        <Modal
          isOpen={this.modalOpen}
          className={'modal'}
          overlayClassName={'modal-overlay'}
          onRequestClose={this.toggleModal}
          onAfterOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onAfterClose={() => {
            document.body.removeAttribute('style');
          }}
        >
          <div className="top">
            <h5>
              <Translate component="dashboard" keyword="konto" />
            </h5>
            <div className="close-modal" onClick={this.toggleModal}>
              <h5>
                <Translate component="modal-content" keyword="close" />
              </h5>
              <img src={Close} />
            </div>
          </div>
          <div className="user-drawer">
            <LoginForm toggle={this.toggleModal} />
            <RegisterForm toggle={this.toggleModal} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default ProfileButton;
