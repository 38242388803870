declare const CookieScript;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Rings } from 'react-loader-spinner';
import TvProduct from './components/TvProduct/TvProduct';

const BUFFER = 5000;

const GET_SCREEN = gql`
  query GetScreen($shop: String!, $screen: String!) {
    screen(shop: $shop, screen: $screen) {
      current {
        contentType
        content
        duration
        startAt
      }
    }
  }
`;

const Tv = () => {
  const { shop, screen } = useParams<{ shop: string; screen: string }>();
  const { data, refetch } = useQuery(GET_SCREEN, {
    variables: { shop, screen },
    pollInterval: 30000,
  });

  const startAt = data?.screen?.current?.startAt;
  const duration = (data?.screen?.current?.duration || 0) * 1000;

  useEffect(() => {
    CookieScript?.instance?.acceptAllAction();

    // Hide cookie badge
    setTimeout(() => {
      const cookieBadge = document.getElementById('cookiescript_badge');
      if (cookieBadge) {
        cookieBadge.style.display = 'none';
      }
    }, 1000);
    setTimeout(() => {
      const cookieBadge = document.getElementById('cookiescript_badge');
      if (cookieBadge) {
        cookieBadge.style.display = 'none';
      }
    }, 2000);
    setTimeout(() => {
      const cookieBadge = document.getElementById('cookiescript_badge');
      if (cookieBadge) {
        cookieBadge.style.display = 'none';
      }
    }, 3000);
  }, []);

  useEffect(() => {
    if (!startAt || !duration) {
      return;
    }

    let timer;
    const startAtTimestamp = new Date(startAt).getTime();
    const nextTimeout = startAtTimestamp + duration + BUFFER - Date.now();

    if (nextTimeout) {
      timer = setTimeout(() => {
        refetch();
      }, nextTimeout);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [startAt, duration]);

  if (!data) {
    return (
      <div className="grid h-screen place-items-center">
        <Rings
          height="100"
          width="100"
          color="#00A053"
          visible={true}
          ariaLabel="loading"
        />
      </div>
    );
  }

  if (data.screen.current?.contentType === 'PRODUCT') {
    return <TvProduct slug={data.screen.current.content} />;
  }

  if (data.screen.current?.contentType === 'IMAGE') {
    return <img src={data.screen.current.content} />;
  }
  if (data.screen.current?.contentType === 'VIDEO') {
    return (
      <video
        autoPlay
        muted
        src={data.screen.current.content}
        className="absolute z-10 w-full h-full bg-black"
      />
    );
  }
  return null;
};

export default Tv;
