declare const _learnq;

import ProductModel from '../models/Product';
import config from '../services/Config';

export const init = () => {
  if (config.klaviyoDisabled) {
    return;
  }

  const klaviyoUrl = '//static.klaviyo.com/onsite/js/klaviyo.js';

  const scriptElement = document.createElement('script');
  scriptElement.type = 'text/javascript';
  scriptElement.src = `${klaviyoUrl}?company_id=${config.klaviyoCompanyId}`;

  document.getElementsByTagName('head')[0].appendChild(scriptElement);
};

const isLoaded = () => {
  return typeof _learnq !== 'undefined';
};

export const identifyUser = (email, firstName, lastName) => {
  if (config.klaviyoDisabled) {
    return;
  }

  if (isLoaded()) {
    _learnq.push([
      'identify',
      {
        $email: email,
        $first_name: firstName,
        $last_name: lastName,
      },
    ]);
  }
};

export const userRegistered = () => {
  if (config.klaviyoDisabled) {
    return;
  }

  if (isLoaded()) {
    _learnq.push(['track', 'User Registered']);
  }
};

export const productView = (product: ProductModel, auth, products) => {
  if (config.klaviyoDisabled) {
    return;
  }
  const productImages = product.images;
  let cover = null;
  if (productImages?.length) {
    cover = productImages[0];
  }

  const reccProducts = products.map((reccProduct) => {
    const reccProductImages = product.images;
    let reccCover = null;
    if (reccProductImages?.length) {
      reccCover = reccProductImages[0];
    }

    return {
      ProductName: reccProduct.title,
      ProductID: reccProduct.data.id,
      Categories: reccProduct.categories[0].long_title,
      ImageURL: reccCover ? reccCover.urls['W540'] : undefined,
      URL: `https://www.rademar.ee/toode/${reccProduct.slug}`,
      Brand: reccProduct.brandTitle,
      DefaultPrice: reccProduct.defaultPrice,
      ItemPrice: reccProduct.finalPrice(auth),
      Discount: reccProduct.discountPercentage(auth),
      FullTitle: reccProduct.fullTitle(),
    };
  });

  if (isLoaded()) {
    _learnq.push([
      'track',
      'Viewed Product',
      {
        ProductName: product.title,
        ProductID: product.data.id,
        Categories: product.categories[0].long_title,
        ImageURL: cover ? cover.urls['W540'] : undefined,
        URL: `https://www.rademar.ee/toode/${product.slug}`,
        Brand: product.brandTitle,
        DefaultPrice: product.defaultPrice,
        ItemPrice: product.finalPrice(auth),
        Discount: product.discountPercentage(auth),
        FullTitle: product.fullTitle(),
        Items: reccProducts,
      },
    ]);

    _learnq.push([
      'trackViewedItem',
      {
        Title: product.title,
        ItemId: product.data.id,
        Categories: product.categories[0].long_title,
        ImageUrl: cover ? cover.urls['W540'] : undefined,
        URL: `https://www.rademar.ee/toode/${product.slug}`,
        Metadata: {
          Brand: product.brandTitle,
          Price: product.defaultPrice,
        },
      },
    ]);
  }
};

export const addToCart = (cart, auth) => {
  if (config.klaviyoDisabled) {
    return;
  }

  const cartRows = cart.variations.map((variation) => {
    const product = cart.getProduct(variation.product_id);
    const productImages = product.images;
    let cover = null;
    if (productImages?.length) {
      cover = productImages[0];
    }

    return {
      SKU: product.data.id,
      ProductName: product.title,
      Quantity: variation.quantity,
      DefaultPrice: product.defaultPrice,
      ItemPrice: product.finalPrice(auth),
      Discount: product.discountPercentage(auth, variation.quantity),
      RowTotal: product.finalPrice(auth, variation.quantity),
      ProductURL: `https://www.rademar.ee/toode/${product.slug}`,
      ImageURL: cover ? cover.urls['W540'] : undefined,
      ProductCategories: product.categoryTitle,
      FullTitle: product.fullTitle(),
      ProductBrand: product.brandTitle,
    };
  });

  const itemNames = cartRows.map((product) => {
    return product.ProductName;
  });

  if (isLoaded()) {
    _learnq.push([
      'track',
      'Add To Cart',
      {
        $value: cart.totalSum,
        $shippingCost: cart.shippingTotal,
        $itemsTotal: cart.itemsTotal,
        $ItemNames: itemNames,
        CheckoutURL: 'https://www.rademar.ee/checkout',
        Items: cartRows,
      },
    ]);
  }
};

export const initiateCheckout = (cart, auth) => {
  if (config.klaviyoDisabled) {
    return;
  }

  const cartRows = cart.variations.map((variation) => {
    const product = cart.getProduct(variation.product_id);
    const productImages = product.images;
    let cover = null;
    if (productImages?.length) {
      cover = productImages[0];
    }

    return {
      SKU: product.data.id,
      ProductName: product.title,
      Quantity: variation.quantity,
      DefaultPrice: product.defaultPrice,
      ItemPrice: product.finalPrice(auth),
      Discount: product.discountPercentage(auth, variation.quantity),
      RowTotal: product.finalPrice(auth, variation.quantity),
      ProductURL: `https://www.rademar.ee/toode/${product.slug}`,
      ImageURL: cover ? cover.urls['W540'] : undefined,
      ProductCategories: product.categoryTitle,
      FullTitle: product.fullTitle(),
      ProductBrand: product.brandTitle,
    };
  });

  const itemNames = cartRows.map((product) => {
    return product.ProductName;
  });

  if (isLoaded()) {
    _learnq.push([
      'track',
      'Started Checkout',
      {
        $value: cart.totalSum,
        $shippingCost: cart.shippingTotal,
        $itemsTotal: cart.itemsTotal,
        $ItemNames: itemNames,
        CheckoutURL: 'https://www.rademar.ee/checkout',
        Items: cartRows,
      },
    ]);
  }
};
