import React from 'react';
import LocalizedLink from '../../../components/LocalizedLink';
import Image, { ImageData } from '../../Image';
import { Sizes } from '../../../constants/Image';
import './styles/index.scss';

interface Props {
  data?: {
    title: string;
    slug: string;
    preview: string;
    content: string;
    createdAt: string;
    categories: { title: string }[];
    cover: ImageData;
  };
}

export default class BlogItem extends React.Component<Props> {
  public render() {
    const { data } = this.props;

    let image;
    if (data.cover) {
      image = (
        <Image
          image={data.cover}
          width="800"
          height="435"
          defaultSize={Sizes.W800}
          alt={data.title}
        />
      );
    }

    let categories;
    if (data.categories) {
      categories = data.categories.map((cat, index) => {
        return <span key={index}>{cat.title}</span>;
      });
    }

    return (
      <LocalizedLink to={`/blogi/${data.slug}`} className="component-blogItem">
        <div className="component-blogItem__image">{image}</div>
        {/* <p className="component-blogItem__new">Uus postitus</p> */}
        <div className="component-blogItem__content">
          <div className="component-blogItem__header">
            <p className="component-blogItem__date">{data.createdAt}</p>
            <div className="component-blogItem__categories">{categories}</div>
          </div>
          <h2 className="component-blogItem__title">{data.title}</h2>
        </div>
      </LocalizedLink>
    );
  }
}
