import React from 'react';
import RightArrow from '../../images/block-right.svg';

const Right = ({
  currentSlide,
  slideCount,
  ...props
}: {
  currentSlide?: number;
  slideCount?: number;
}) => (
  <div {...props} data-currentslide={currentSlide} data-slidecount={slideCount}>
    <img src={RightArrow} alt="Edasi" />
  </div>
);

export default Right;
