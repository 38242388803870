import { observable, action, makeObservable } from 'mobx';
import { identifyUser } from '../services/Klaviyo';

import Ajax from '../services/Ajax';

type UserInfo = {
  email: string;
  phone: string;
  firstname: string;
  lastname: string;
  roles: { id: number; name: string }[];
};

export default class User {
  @observable loaded = false;
  @observable email = '';
  @observable phone = '';
  @observable firstname = '';
  @observable lastname = '';
  @observable roles = [];

  constructor() {
    makeObservable(this);
  }

  @action setUserInfo(userInfo: UserInfo) {
    this.email = userInfo.email || '';
    this.phone = userInfo.phone || '';
    this.firstname = userInfo.firstname || '';
    this.lastname = userInfo.lastname || '';
    this.roles = userInfo.roles.map((role) => role.name);
    this.loaded = true;
  }

  async loadUserOrders() {
    const { data } = await Ajax.get('user-orders');
    return data;
  }

  async loadInfo() {
    const { data } = await Ajax.get('user');
    this.setUserInfo(data);

    identifyUser(this.email, this.firstname, this.lastname);
    return data;
  }

  async update(firstnameNew, lastnameNew, emailNew, phoneNew) {
    const { data } = await Ajax.post('user/update', {
      email: emailNew,
      firstname: firstnameNew,
      lastname: lastnameNew,
      phone: phoneNew,
    });

    this.setUserInfo(data);
  }
}
