import { domainSpecificApiUrl, isDev } from './Env';

const DEFAULT_CONFIG = {
  // Main API endpoint
  api: 'https://api-dev.rademar.ee/api/',
  graphQl: 'https://graphql-dev.rademar.ee/',

  // Klaviyo service configuration
  klaviyoDisabled: true,
  klaviyoCompanyId: 'JhfNvC',

  filterWhitelist: [
    'category',
    'color',
    'size',
    'brand',
    'outlet',
    'isOutlet',
    'extraDiscount',
  ],

  algoliaAppId: 'WL2J24XTNX',
  algoliaSearchKey: 'c6429177c20a37546e09efc482ca7173',
  algoliaSearchIndex: 'dev_rademar',
  algoliaRuSearchIndex: 'dev_ru_rademar',
};

let finalConfig = DEFAULT_CONFIG;

// Different configuration for live environment
if (!isDev()) {
  finalConfig = Object.assign({}, DEFAULT_CONFIG, {
    api: 'https://api.rademar.ee/api/',
    graphQl: 'https://graphql.rademar.ee/',
    klaviyoDisabled: false,
    algoliaSearchKey: '0ce88264775f511d146eb792bdd0e900',
    algoliaSearchIndex: 'rademar',
    algoliaRuSearchIndex: 'ru_rademar',
  });
}

// Set LV domain api url
finalConfig.api = domainSpecificApiUrl(finalConfig.api);

export default finalConfig;
