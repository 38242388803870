import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';

import Blocks from '../../../stores/Blocks';

import ProductBlock from '../../ProductBlock';
import Product from '../../../models/Product';

import Slider, { LazyLoadTypes } from 'react-slick';
import Left from '../../SlickArrow/Left';
import Right from '../../SlickArrow/Right';

import './styles/index.scss';

interface Props {
  blocks?: Blocks;
  type?: string;
  slug?: string;
}

@inject('blocks')
@observer
export default class Block extends React.Component<Props> {
  @observable title = '';
  @observable currentCategory = '';
  @observable categories = [];
  @observable products = [];

  @observable loading = true;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.onCategoryClick = this.onCategoryClick.bind(this);
  }

  public componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    const { slug } = this.props;
    if (slug !== prevProps.slug) {
      this.load();
    }
  }

  async load() {
    const { type, blocks, slug } = this.props;
    const data = await blocks.loadBlock(type);

    if (slug) {
      this.setTitle(data.title);
      const products = await blocks.loadProducts(type, slug);
      this.setProducts(products);
      return;
    }

    if (data) {
      this.setTitle(data.title);
      this.setCategories(data.categories);
      this.setCurrentCategory(data.categories[0]);

      const products = await blocks.loadProducts(type, this.currentCategory);
      this.setProducts(products);
    }
  }

  async updateProducts() {
    const { type, blocks } = this.props;
    this.setProducts(await blocks.loadProducts(type, this.currentCategory));
  }

  @action setProducts(products) {
    this.products = products.map((product) => new Product(product));
    this.loading = false;
  }

  @action setTitle(title) {
    this.title = title;
  }

  @action setCategories(categories) {
    this.categories = categories;
  }

  @action setCurrentCategory(category) {
    this.currentCategory = category.slug;
  }

  onCategoryClick(category) {
    this.setCurrentCategory(category);
    this.updateProducts();
  }

  public render() {
    const sliderConfig = {
      className: 'block-slider',
      lazyLoad: 'ondemand' as LazyLoadTypes,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 5,
      infinite: true,
      dots: true,
      dotsClass: 'awesome-dots',
      customPaging: () => {
        return <span />;
      },
      prevArrow: <Left />,
      nextArrow: <Right />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            infinite: false,
            slidesToShow: 1.25,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    };

    const categories = this.categories.map((category) => (
      <div key={category.slug}>
        <li
          className={this.currentCategory === category.slug ? 'active' : ''}
          onClick={this.onCategoryClick.bind(this, category)}
        >
          {category.title}
        </li>
        <li className="spacer">/</li>
      </div>
    ));

    const productsList = this.products.map((product) => (
      <ProductBlock key={product.slug} product={product} slider={true} />
    ));

    if (this.loading) {
      return null;
    }

    return (
      <div className="products-block">
        <h4 className="title">{this.title}</h4>
        <div className="categories">
          <ul>{categories}</ul>
        </div>
        <div className="products-slick">
          <Slider {...sliderConfig}>{productsList}</Slider>
        </div>
      </div>
    );
  }
}
