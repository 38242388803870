import React from 'react';
import MainMenu from '../MainMenu/MainMenu';

import './styles/index.scss';

export default class Header extends React.Component {
  public render() {
    return (
      <header className="header">
        <MainMenu />
      </header>
    );
  }
}
