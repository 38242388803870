import React, { useState, useEffect } from 'react';
import useStores from '../../hooks/useStores';
import LocalizedLink from '../LocalizedLink';
import Image from '../Image';
import { Sizes } from '../../constants/Image';
import QuantityButton from '../Checkout/QuantityButton';
import Price from '../Price/Price';
import Translate from '../Translate/Translate';
import { observer } from 'mobx-react';
import './styles/MiniCart.scss';
import Modal from '../Modal';
import InBankModal from '../Product/InBankModal';
import ClickAndTryModal from '../Product/ClickAndTryModal';
import Uus from '../../images/uus.svg';
import Stroke from '../../images/underline-stroke.svg';

const MiniCart = () => {
  const { cart } = useStores();
  const [cartItems, setCartItems] = useState([]);
  const [cartOpen, setCartOpen] = useState(false);

  useEffect(() => {
    setCartItems(cart.variations);
  }, [cart.variations]);

  const removeProduct = async (variation, quantity) => {
    await cart.removeItem(variation.code, quantity);
    setCartItems(cart.variations);
  };

  useEffect(() => {
    return () => {
      document.querySelector('body').classList.remove('scroll-lock');
    };
  }, []);

  const openCart = () => {
    setCartOpen(true);
    document.querySelector('body').classList.add('scroll-lock');
  };

  const closeCart = () => {
    setCartOpen(false);
    document.querySelector('body').classList.remove('scroll-lock');
  };

  return (
    <div className="component-miniCart">
      <div
        className={`component-miniCart-button ${
          cart.nrOfItems >= 1 ? 'has-items' : ''
        }`}
        onClick={cart.nrOfItems ? openCart : null}
      >
        <svg>
          <path
            d="M22.9,5.7c0-0.5-0.4-0.9-0.9-0.9h-5.3C16.5,2,14-0.2,11.2,0C8.6,0.2,6.5,2.3,6.3,4.9H0.9C0.4,4.9,0,5.2,0,5.7
	V22c0.1,2.1,1.9,3.7,4,3.6h15c2.1,0.1,3.8-1.5,4-3.6L22.9,5.7z M8.2,4.9c0.2-1.8,1.8-3.1,3.6-3c1.6,0.1,2.8,1.4,3,3H8.2z M21.1,6.7
	V22c-0.1,1-1.1,1.8-2.1,1.7H4c-1,0-2.1-0.7-2.1-1.6L1.8,6.7L21.1,6.7z"
          />
        </svg>
        <div className="component-miniCart-nrOfItems">{cart.nrOfAllItems}</div>
      </div>
      <div
        className={`component-miniCart-container ${cartOpen ? 'open' : null}`}
      >
        <div className="component-miniCart-header">
          <h4 className="component-miniCart-title">
            <Translate component="mini-cart" keyword="cart" />
          </h4>
          <p className="component-miniCart-close" onClick={closeCart}>
            <Translate component="modal-content" keyword="close" />
          </p>
        </div>
        {cart.nrOfItems ? (
          <>
            <div className="flex flex-wrap -mx-2 my-0">
              {cartItems.length
                ? cartItems.map((variation, index) => {
                    const product = cart.getProduct(variation.product_id);

                    if (product) {
                      return (
                        <div key={index} className="flex pt-9 pb-0 px-2.5">
                          <LocalizedLink
                            className="mr-4"
                            to={`/toode/${product.slug}`}
                            onClick={closeCart}
                          >
                            <Image
                              className={
                                'rounded bg-rgrey-lightlight object-cover max-w-32 md:max-w-44 min-w-32 h-auto w-auto'
                              }
                              defaultSize={Sizes.W800}
                              image={product.cover}
                              allSizes={[Sizes.W300, Sizes.W540, Sizes.W800]}
                            />
                          </LocalizedLink>
                          <div className="component-miniCart-product__content">
                            <div className="component-miniCart-product__titles">
                              <p className="component-miniCart-product__brandTitle">
                                {product.brandTitle} {product.gridTitle}
                              </p>
                              <h3 className="component-miniCart-product__productTitle">
                                {product.title}
                              </h3>
                            </div>
                            <div className="component-miniCart-product__sizes">
                              <span className="component-miniCart-product__size">
                                {variation.value}
                              </span>
                              {product.product_code !== '4370' &&
                              product.product_code !== '4340' ? (
                                <QuantityButton data={variation} />
                              ) : null}
                            </div>
                            <Price
                              prices={product.prices(true)}
                              priceLabel={true}
                              discountPriceClassname="text-base mr-1"
                              defaultPriceClassName="text-xs text-nowrap"
                              discountLabelClassname="text-xs"
                            />
                          </div>
                          {product.product_code !== '4370' &&
                          product.product_code !== '4340' ? (
                            <div
                              className="component-miniCart-product__remove"
                              onClick={() =>
                                removeProduct(variation, variation.quantity)
                              }
                            ></div>
                          ) : null}
                        </div>
                      );
                    }
                  })
                : null}
            </div>
            <div className="component-miniCart-footer">
              <div className="component-miniCart-footer__inner">
                <div className="flex items-center gap-2 my-5 pb-5 border-b">
                  <div className="text-rgreen">
                    <div className="inline-block relative">
                      <img
                        src={Uus}
                        alt="uus"
                        className="w-8 h-8 inline-block"
                      />
                      <img
                        src={Stroke}
                        alt="underline brush stroke"
                        className="absolute inset-x-0 w-full -mt-2"
                      />
                    </div>
                  </div>
                  <div className="font-axi-bold text-wrap">
                    <Translate component="checkout" keyword="modena-payment" />
                  </div>
                  <Modal
                    buttonClass="rounded-full text-rgrey border border-rgrey shrink-0 w-5 h-5 text-center py-0.5 justify-center items-center hover:text-white hover:bg-black hover:border-black hover:cursor-pointer"
                    button="i"
                    content={<ClickAndTryModal />}
                    contentTitle=""
                    modalClass="center overflow-auto min-h-96 md:min-h-fit"
                  />
                </div>
                {cart.totalSum <= 2500 && (
                  <div className="mb-5 pb-5 border-b">
                    <div className="flex items-center gap-2 mb-2">
                      <div className="font-axi-bold">
                        Maksa 3 või 6 võrdses osas
                      </div>
                      <Modal
                        buttonClass="rounded-full text-rgrey border border-rgrey shrink-0 w-5 h-5 text-center py-0.5 justify-center items-center hover:text-white hover:bg-black hover:border-black hover:cursor-pointer"
                        button="i"
                        content={<InBankModal price={cart.totalSum} />}
                        contentTitle={
                          <img
                            src="https://rademar-1b47c.kxcdn.com/icons/inbank.svg"
                            alt="inbank"
                            className="h-10 w-auto m-5"
                          />
                        }
                        modalClass="center overflow-auto min-h-96 md:min-h-fit"
                      />
                    </div>
                    <div className="flex gap-4 mr-2">
                      <div className="text-rpink text-nowrap whitespace-nowrap">
                        3<span className="text-xs"> &#10005; </span>
                        {(cart.totalSum / 3).toFixed(2)} €
                      </div>
                      <div className="text-rpink text-nowrap whitespace-nowrap">
                        6<span className="text-xs"> &#10005; </span>
                        {(cart.totalSum / 6).toFixed(2)} €
                      </div>
                    </div>
                    {cart.totalSum < 50 && (
                      <div className="text-xs tracking-tight mt-2">
                        Maksete jaotamiseks peab ostukorv olema vahemikus
                        50-2500 eur.
                      </div>
                    )}
                  </div>
                )}

                <p>
                  <Translate component="checkout" keyword="right-sum" />
                  <span>{cart.originalTotal} €</span>
                </p>
                {cart.totalDiscount == 0 ? null : (
                  <p>
                    <Translate component="checkout" keyword="discount-sum" />
                    <span> -{cart.totalDiscount} €</span>
                  </p>
                )}
                <p>
                  <Translate component="checkout" keyword="right-delivery" />
                  <span>{cart.shippingTotal} €</span>
                </p>
                <p>
                  <Translate component="checkout" keyword="right-total" />
                  <span>{cart.totalSum} €</span>
                </p>
              </div>
              <LocalizedLink
                className="component-miniCart-pay"
                to="/checkout"
                onClick={closeCart}
              >
                <Translate component="checkout" keyword="button" />
              </LocalizedLink>
            </div>
          </>
        ) : (
          <div className="component-miniCart-empty">
            <h4>
              <Translate component="mini-cart" keyword="empty" />
            </h4>
          </div>
        )}
      </div>
      <div
        className={`component-miniCart-overlay ${cartOpen ? 'open' : null}`}
        onClick={closeCart}
      />
    </div>
  );
};

export default observer(MiniCart);
