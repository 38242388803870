import React, { useEffect, useState } from 'react';
import { translate } from '../../services/Translations';

interface Props {
  component: string;
  keyword: string;
}

const Translate = ({ component, keyword }: Props) => {
  const [loading, setLoading] = useState(true);
  const [translatedText, setTranslatedText] = useState('');

  useEffect(() => {
    const t = async () => {
      setLoading(true);
      const translation = await translate(component, keyword);
      setTranslatedText(translation);
      setLoading(false);
    };

    t();
  }, [component, keyword]);

  // No need to show loader or anything while loading translations
  if (loading) {
    return null;
  }

  return <>{translatedText}</>;
};

export default Translate;
