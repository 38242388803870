import React from 'react';
import WarningImage from '../../images/warning.svg';
import SuccessImage from '../../images/confirm.svg';

import './index.scss';

type Props = {
  show: boolean;
  type: string;
  children: React.ReactNode;
};

export enum Type {
  ERROR = 'error', // red
  WARNING = 'warning', // yellow
  SUCCESS = 'success', // green
  INFO = 'info', // blue
}

const Alert = ({ children, show, type }: Props) => {
  let image = WarningImage;

  if (!show) {
    return null;
  }

  if (type === 'success') {
    image = SuccessImage;
  }

  return (
    <div className={`alert ${type}`}>
      <div className="alert__border" />
      <div className="alert__image">
        <img src={image} alt="warning" />
      </div>
      <div className="alert__content">
        <p className="alert__content__item">{children}</p>
      </div>
    </div>
  );
};

export default Alert;
