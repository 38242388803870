import { v4 as uuidv4 } from 'uuid';

export function getDeviceId() {
  const deviceId = localStorage.getItem('rademar-ee-device');
  if (deviceId) {
    return deviceId;
  }

  const newDeviceId = uuidv4();
  localStorage.setItem('rademar-ee-device', newDeviceId);
  return newDeviceId;
}

export function authTokenName() {
  return `rademar-ee-${getDeviceId()}`;
}
