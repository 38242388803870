import { gql } from '@apollo/client';
import apolloClient from './Apollo';

const GET_TRANSLATION = gql`
  query Translations($components: [String]) {
    translations(components: $components) {
      id
      component
      translations {
        id
        slug
        translation
      }
    }
  }
`;

export const translate = async (
  component: string,
  keyword: string,
): Promise<string> => {
  const { data } = await apolloClient.query({
    query: GET_TRANSLATION,
    variables: { components: [component] },
  });

  const { translations } = data;
  const componentTranslations = translations.find(
    (t) => t.component === component,
  );

  // Probably no translations for requested component, or request failed
  if (!componentTranslations?.translations) {
    return '';
  }

  const requestedTranslation = componentTranslations.translations.find(
    (t) => t.slug === keyword,
  );

  // No translation for requested keyword
  if (!requestedTranslation) {
    return '';
  }

  return requestedTranslation.translation;
};
