import React from 'react';
import { useNavigate } from 'react-router-dom';
import Register from './Register';

const RegisterIndex = ({ toggle }: { toggle: () => void }) => {
  const navigate = useNavigate();
  return <Register navigate={navigate} toggle={toggle} />;
};

export default RegisterIndex;
