const LV_DOMAIN = '.lv';
const EE_DOMAIN = '.ee';

export const isLocal = () => {
  return (
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'arendus.rademar.ee' ||
    window.location.hostname === 'arendus.rademar-api.test' ||
    window.location.hostname === 'local.rademar.ee'
  );
};

export const isDev = () => {
  return isLocal() || window.location.hostname.startsWith('ui-dev.');
};

export const isProd = () => {
  return !isDev();
};

export const isLvDomain = () => {
  return window.location.hostname.endsWith(LV_DOMAIN);
};

export const domainSpecificApiUrl = (originalUrl: string) => {
  // Use LV api endpoint if we are on LV domain
  if (isLvDomain()) {
    return originalUrl.replace(EE_DOMAIN, LV_DOMAIN);
  }

  return originalUrl;
};
