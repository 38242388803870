import React, { useState } from 'react';
import SubmenuClickable from './SubmenuClickable';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

type Props = {
  title: string;
  id: string;
  slug: string;
  subCategories: {
    title: string;
    id: string;
    slug: string;
  }[];
  generalTitle: string;
  onClick: () => void;
  onPathChange?: () => void;
};

export type PathItem = {
  id: string;
  title: string;
  slug: string;
  generalTitle: string;
};

const MainMenuItemClickable = ({
  title,
  id,
  slug,
  generalTitle,
  onClick,
  onPathChange,
}: Props) => {
  const [currentSubmenuPath, setCurrentSubmenuPath] = useState<PathItem[]>([]);

  return (
    <div className="flex cursor-pointer border rounded mt-2.5 border-solid border-rgrey-light">
      <button
        className="flex flex-nowrap uppercase leading-7 flex-1 px-4 py-3.5 font-axi-bold text-start justify-between items-center"
        onClick={() => {
          setCurrentSubmenuPath([{ id, title, slug, generalTitle }]);
          if (onPathChange) {
            onPathChange();
          }
        }}
      >
        {title}
        <ChevronRightIcon className="w-6 h-6 " />
      </button>

      {currentSubmenuPath.length ? (
        <div className="absolute top-0 right-0 left-0 bottom-0 bg-white z-50 px-4 overflow-y-scroll">
          <SubmenuClickable
            path={currentSubmenuPath as [PathItem]}
            onSetPath={(newPath) => {
              setCurrentSubmenuPath(newPath);
              if (onPathChange) {
                onPathChange();
              }
            }}
            generalTitle={generalTitle}
            slug={slug}
            onClick={onClick}
          />
        </div>
      ) : null}
    </div>
  );
};

export default MainMenuItemClickable;
