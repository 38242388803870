export default class Banner {
  data = null;

  constructor(data) {
    this.data = data;
  }

  get cover() {
    return this.data.cover ? this.data.cover : undefined;
  }

  get images() {
    return this.data.media ? this.data.media : [];
  }

  get desktop() {
    return this.data.media.find((image) => image.collection_name === 'banner');
  }

  get mobile() {
    return this.data.media.find((image) => image.collection_name === 'mobile');
  }

  get button() {
    return this.data.button;
  }

  get content() {
    return this.data.content;
  }

  get title() {
    return this.data.title;
  }

  get subtitle() {
    return this.data.subtitle;
  }

  get slug() {
    return this.data.slug;
  }

  get url() {
    return this.data.url;
  }

  get id() {
    return this.data.id;
  }

  get component() {
    return this.data.component;
  }

  get collection() {
    return this.data.collection;
  }
}
