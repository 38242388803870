import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import {
  Language,
  LANG_LOCAL_STORAGE_KEY,
  LANG_URL_PARAM,
} from '../stores/Translations';
import config from './Config';
import { getItem } from './Memory';
import { getPersonalisationKey } from './Personalisation';
import { authTokenName } from './Device';

const storedLanguage = getItem(LANG_LOCAL_STORAGE_KEY) || Language.ET;

const httpLink = new HttpLink({
  uri: config.graphQl,
  fetch: (uri, options) => {
    // Inject language to original request body.
    const requestBody = JSON.parse(options.body.toString());
    requestBody[LANG_URL_PARAM] = storedLanguage;
    options.body = JSON.stringify(requestBody);

    return fetch(uri, options);
  },
});

const batchHttpLink = new BatchHttpLink({
  uri: config.graphQl,
  fetch: (uri, options) => {
    // Inject language to original request body.
    const operations = JSON.parse(options.body.toString());
    operations.forEach((operation) => {
      operation[LANG_URL_PARAM] = storedLanguage;
    });

    options.body = JSON.stringify(operations);

    return fetch(uri, options);
  },
  batchMax: 10,
  batchInterval: 30,
});

const authLink = setContext((_, { headers }) => {
  const key = getPersonalisationKey();
  const additionalHeaders = {};

  if (key) {
    additionalHeaders['rademar-personalisation'] = key;
  }

  const authToken = localStorage.getItem(authTokenName());
  if (authToken) {
    additionalHeaders['authorization'] = `Bearer ${authToken}`;
  }

  return {
    headers: {
      ...headers,
      ...additionalHeaders,
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const apolloBatchClient = new ApolloClient({
  link: authLink.concat(batchHttpLink),
  cache: new InMemoryCache(),
});

export default apolloClient;
