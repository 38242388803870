import React, { useState } from 'react';
import BikeBg from '../../../images/bikebg.svg';
import Pencil from '../../../images/pencil.svg';
import Pointer from '../../../images/mouse-pointer.svg';
import Send from '../../../images/send.svg';
import Bike from '../../../images/redbike.svg';
import Logo from '../../../images/rademar.svg';
import Trek from '../../../images/trek.svg';
import LocalizedLink from '../../LocalizedLink';
import Translate from '../../../components/Translate/Translate';
import { gql, useMutation } from '@apollo/client';

const SEND = gql`
  mutation Mutation(
    $createPreorderFirstname: String!
    $createPreorderLastname: String!
    $createPreorderEmail: String!
    $createPreorderPhone: String!
    $createPreorderExtraInformation: String
    $createPreorderVariationDescription: String
    $createPreorderVariationId: ID
  ) {
    createPreorder(
      firstname: $createPreorderFirstname
      lastname: $createPreorderLastname
      email: $createPreorderEmail
      phone: $createPreorderPhone
      extraInformation: $createPreorderExtraInformation
      variationDescription: $createPreorderVariationDescription
      variationId: $createPreorderVariationId
    ) {
      createdAt
    }
  }
`;

const BikeOrder = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    info: '',
  });
  const [createPreorder] = useMutation(SEND);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await createPreorder({
        variables: {
          createPreorderFirstname: formData.firstName,
          createPreorderLastname: formData.lastName,
          createPreorderEmail: formData.email,
          createPreorderPhone: formData.phone,
          createPreorderExtraInformation: formData.info,
          // createPreorderVariationDescription: variation,
          // createPreorderVariationId: id,
        },
      });
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        info: '',
      });

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <>
      <div className="relative">
        <img
          src={BikeBg}
          alt=""
          className="aspect-[5/2] w-full object-cover rounded-lg "
        />
        <div className="flex absolute md:left-10 md:top-10 left-5 top-5 gap-8 w-1/3 md:w-2/5 items-center">
          <img src={Logo} alt="" className="max-w-96" />
          <img src={Trek} alt="" className="max-w-80" />
        </div>
        <img src={Bike} alt="" className="absolute w-3/5 right-0 top-5" />
        <h4 className="text-5xl leading-relaxed font-axi-bold my-10 absolute left-10 top-32 w-3/6 hidden 2xl:block">
          Neque porro quisquam est qui dolorem
        </h4>
        <p className="md:w-2/6 mx-auto text-base leading-6 absolute left-10 bottom-52 hidden 2xl:block">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum
          impedit neque libero illo ex reiciendis? Unde voluptate porro numquam
          perferendis voluptatem pariatur!
        </p>
        <a href="#form">
          <button className="bg-rgreen w-40 text-white px-3 py-4 rounded font-axi-bold hover:ring-2 hover:ring-green-600 cursor-pointer absolute left-10 bottom-20 hidden md:block">
            Vormista tellimus
          </button>
        </a>
      </div>
      <div className="mt-24 md:mt-48 mx-auto text-center ">
        <h4 className="text-4xl leading-10 my-10">Tellimise protsess</h4>
        <p className="text-center md:w-2/6 mx-auto text-base leading-6">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum
          impedit neque libero illo ex reiciendis? Unde voluptate porro numquam
          perferendis voluptatem pariatur!
        </p>
        <div className="grid md:grid-cols-3 grid-flow-row gap-4 mt-10">
          <div className="grid gap-8">
            <div className="rounded-full bg-rgreen-light w-24 h-24 mx-auto flex justify-center items-center">
              <img src={Pointer} alt="" className="w-10 h-10 " />
            </div>
            <span className="text-2xl leading-8 font-axi-bold">
              1. Vali sobiv ratas
            </span>
            <p className="w-3/6 mx-auto">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Explicabo, praesentium id.
            </p>
          </div>
          <div className="grid gap-8">
            <div className="rounded-full bg-rgreen-light w-24 h-24 mx-auto flex justify-center items-center">
              <img src={Pencil} alt="" className="w-10 h-10 " />
            </div>
            <span className="text-2xl leading-8 font-axi-bold">
              2. Täida vorm
            </span>
            <p className="w-3/6 mx-auto">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Explicabo, praesentium id.
            </p>
          </div>
          <div className="grid gap-8">
            <div className="rounded-full bg-rgreen-light w-24 h-24 mx-auto flex justify-center items-center">
              <img src={Send} alt="" className="w-10 h-10 " />
            </div>
            <span className="text-2xl leading-8 font-axi-bold">
              3. Tellimise protsess
            </span>
            <p className="w-3/6 mx-auto">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Explicabo, praesentium id.
            </p>
          </div>
        </div>
        <a href="#form">
          <button className="bg-rgreen w-40 my-10 text-white px-3 py-4 rounded font-axi-bold hover:ring-2 hover:ring-green-600 cursor-pointer">
            Vormista tellimus
          </button>
        </a>
        <hr />
        <h4 className="text-4xl leading-10 mt-20 ">Trek tootevalik</h4>
        <p className="text-center md:w-2/6 mx-auto text-base leading-6 mt-10">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum
          impedit neque libero illo ex reiciendis? Unde voluptate porro numquam
          perferendis voluptatem pariatur!
        </p>
        <div className="grid md:grid-cols-3 grid-flow-row gap-8 mt-10">
          <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80"></div>
          <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80"></div>
          <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80"></div>
          <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80"></div>
          <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80"></div>
          <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80"></div>
        </div>
        <LocalizedLink to="https://www.rademar.ee/tooted/rattad?brand=Trek">
          <button className="text-base leading-6 font-axi-bold border rounded px-3 py-4 my-10 hover:bg-black hover:text-white">
            Tutvu kogu tootevalikuga
          </button>
        </LocalizedLink>
      </div>

      <div className="w-full bg-gradient-to-r from-pink-50 to-rgreen-light p-20">
        <h4 id="form" className="text-4xl leading-10 my-10 text-center">
          Vormista tellimus
        </h4>
        <p className="text-center md:w-2/6 mx-auto text-base leading-6">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum
          impedit neque libero illo ex reiciendis? Unde voluptate porro numquam
          perferendis voluptatem pariatur!
        </p>
        <form
          onSubmit={handleSubmit}
          className="md:w-3/6 mx-auto mt-10 md:mt-20"
        >
          <div className="grid grid-cols-2 gap-x-2 gap-y-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <Translate component="checkout" keyword="firstname" />
              </label>
              <div className="mt-2">
                <input
                  required
                  type="text"
                  value={formData.firstName}
                  onChange={handleChange}
                  name="firstName"
                  id="firstName"
                  className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <Translate component="checkout" keyword="lastname" />
              </label>
              <div className="mt-2">
                <input
                  required
                  type="text"
                  value={formData.lastName}
                  onChange={handleChange}
                  name="lastName"
                  id="lastName"
                  className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <Translate component="checkout" keyword="phone" />
              </label>
              <div className="mt-2">
                <input
                  required
                  type="text"
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <Translate component="checkout" keyword="email" />
              </label>
              <div className="mt-2">
                <input
                  required
                  type="text"
                  value={formData.phone}
                  onChange={handleChange}
                  name="phone"
                  id="phone"
                  className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="sm:col-span-3 mt-4">
            <label
              htmlFor="info"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <Translate
                component="checkout"
                keyword="parcel-additional-info"
              />
            </label>
            <div className="mt-2">
              <input
                type="text"
                value={formData.info}
                onChange={handleChange}
                name="info"
                id="info"
                className="block w-full h-20 rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="mt-5 grid grid-cols-2 gap-x-2 gap-y-4 sm:grid-cols-6 items-center">
            <div className="sm:col-span-3 flex items-center">
              <input
                required
                type="checkbox"
                className="h-8 w-8 rounded shrink-0 border-rgrey-light text-rgreen focus:ring-rgreen cursor-pointer mr-5"
              />
              <p className="underline">
                <LocalizedLink to={'/tingimused'}>
                  <Translate component="checkout" keyword="terms" />
                </LocalizedLink>
              </p>
            </div>
            <button
              type="submit"
              className="sm:col-span-3 block w-full h-12 leading-6 font-axi-bold rounded-md  text-white bg-rgreen cursor-pointer focus:ring-2 focus:ring-green-600"
            >
              <Translate component="user-orders" keyword="place-order" />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default BikeOrder;
