import { observable, action, computed, makeObservable } from 'mobx';
import Ajax from '../services/Ajax';
import { isLvDomain } from '../services/Env';

export default class Checkout {
  @observable firstname = '';
  @observable lastname = '';
  @observable email = '';
  @observable phone = '';
  @observable areaCode = '+372';
  @observable shippingMethod = '';
  @observable shippingMethodLabel = '';
  @observable shippingGroup = '';
  @observable shippingMethodHasOptions = false;
  @observable shippingOption = '';
  @observable parcelOptionLabel = '';
  @observable shippingCountry = isLvDomain() ? 'lv' : 'ee';
  @observable shippingCity = '';
  @observable shippingAddress = '';
  @observable shippingZip = '';
  @observable paymentMethod = '';
  @observable paymentMethods = [];
  @observable countries = [];
  @observable termsAgreement = false;

  @observable loading = false;

  @observable firstnameValid = '';
  @observable lastnameValid = '';
  @observable emailValid = '';
  @observable phoneValid = '';
  @observable shippingOptionValid = '';
  @observable shippingCountryValid = 'ok';
  @observable shippingCityValid = '';
  @observable shippingAddressValid = '';
  @observable shippingZipValid = '';
  @observable shippingMethodValid = '';
  @observable termsAgreementValid = false;
  @observable shippingGroupValid = '';
  @observable paymentMethodValid = '';

  constructor() {
    makeObservable(this);
  }

  async loadPaymentMethods() {
    const { data: paymentMethods } = await Ajax.get('payment/methods', {
      params: { countryCode: this.shippingCountry },
    });
    this.setPaymentMethods(paymentMethods);

    return this.paymentMethods;
  }

  async loadCountries() {
    const { data } = await Ajax.get('countries');
    this.setCountries(data);
  }

  @action setCountries(countriesArray: []) {
    this.countries = countriesArray;
  }

  getPaymentMethod(name: string) {
    return this.paymentMethods.find((method) => method.name === name);
  }

  @action setPaymentMethods(methods) {
    this.paymentMethods = methods;
  }

  @action setValue(name: string, value: string | boolean) {
    this[name] = value;
  }

  @action setValues(values: [{ name: string; value: string | boolean }]) {
    values.forEach((value) => {
      this.setValue(value.name, value.value);
    });
  }

  @action validateValue(name: string, value: string | boolean) {
    if (
      name === 'firstname' ||
      name === 'lastname' ||
      name === 'phone' ||
      name === 'paymentMethod'
    ) {
      if (value === '') {
        this[name + 'Valid'] = 'error';
      } else {
        this[name + 'Valid'] = 'ok';
      }
    }

    if (name === 'termsAgreement') {
      if (value === false) {
        this[name + 'Valid'] = 'error';
      } else {
        this[name + 'Valid'] = 'ok';
      }
    }

    if (name === 'email') {
      if (typeof value === 'string' && /\S+@\S+\.\S+/.test(value)) {
        this[name + 'Valid'] = 'ok';
      } else {
        this[name + 'Valid'] = 'error';
      }
    }

    if (name === 'phone') {
      const stringValue = value.toString();
      if (stringValue.length > 3) {
        this[name + 'Valid'] = 'ok';
      } else {
        this[name + 'Valid'] = 'error';
      }
    }

    if (!this.shippingGroup) {
      if (name === 'shippingGroup') {
        if (value === '') {
          this[name + 'Valid'] = 'error';
        } else {
          this[name + 'Valid'] = 'ok';
        }
      }
    }

    if (this.shippingGroup === 'courier') {
      if (
        name === 'shippingMethod' ||
        name === 'shippingCountry' ||
        name === 'shippingCity' ||
        name === 'shippingAddress' ||
        name === 'shippingZip'
      ) {
        if (value === '') {
          this[name + 'Valid'] = 'error';
        } else {
          this[name + 'Valid'] = 'ok';
        }
      }
    }

    if (this.shippingGroup === 'parcel') {
      if (name === 'shippingOption' || name === 'shippingMethod') {
        if (value === '') {
          this[name + 'Valid'] = 'error';
        } else {
          this[name + 'Valid'] = 'ok';
        }
      }
    }

    if (this.shippingGroup === 'rademar') {
      if (name === 'shippingMethod') {
        if (value === '') {
          this[name + 'Valid'] = 'error';
        } else {
          this[name + 'Valid'] = 'ok';
        }
      }
    }
  }

  @computed get isCheckoutReady() {
    if (
      !this.firstname ||
      !this.lastname ||
      !this.email ||
      !this.phone ||
      !this.paymentMethod
    ) {
      return false;
    }

    if (!this.shippingGroup) {
      return false;
    }

    if (!this.termsAgreement) {
      return false;
    }

    if (this.shippingGroup === 'courier') {
      if (
        !this.shippingCountry ||
        !this.shippingCity ||
        !this.shippingAddress ||
        !this.shippingZip ||
        !this.shippingMethod
      ) {
        return false;
      }
    }

    if (this.shippingGroup === 'parcel') {
      if (!this.shippingMethod || !this.shippingOption) {
        return false;
      }
    }

    if (this.shippingGroup === 'rademar') {
      if (!this.shippingMethod) {
        return false;
      }
    }

    return true;
  }

  validateAllFields() {
    const inputFields = [
      'firstname',
      'lastname',
      'email',
      'phone',
      'termsAgreement',
      'shippingGroup',
      'paymentMethod',
    ];
    const courierFields = [
      'shippingCountry',
      'shippingCity',
      'shippingAddress',
      'shippingZip',
      'shippingMethod',
    ];
    const parcelFields = ['shippingOption', 'shippingMethod'];
    const rademarFields = ['shippingMethod'];

    inputFields.forEach((inputField) =>
      this.validateValue(inputField, this[inputField]),
    );

    if (this.shippingGroup === 'parcel') {
      parcelFields.forEach((parcelField) =>
        this.validateValue(parcelField, this[parcelField]),
      );
    }

    if (this.shippingGroup === 'courier') {
      courierFields.forEach((courierField) =>
        this.validateValue(courierField, this[courierField]),
      );
    }

    if (this.shippingGroup === 'rademar') {
      rademarFields.forEach((courierField) =>
        this.validateValue(courierField, this[courierField]),
      );
    }
  }
}
