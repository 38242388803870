import React from 'react';
import LeftArrow from '../../images/block-left.svg';

const Left = ({
  currentSlide,
  slideCount,
  ...props
}: {
  currentSlide?: number;
  slideCount?: number;
}) => (
  <div {...props} data-currentslide={currentSlide} data-slidecount={slideCount}>
    <img src={LeftArrow} alt="Tagasi" />
  </div>
);

export default Left;
