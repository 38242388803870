import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
// import LocalizedLink from '../../LocalizedLink';
import Translate from '../../../components/Translate/Translate';

const Catalog = () => {
  return (
    <div className="md:m-20 ">
      {/* <div className="flex mb-20 gap-8 mt-5">
        <LocalizedLink to="/kauplused">
          <div className="text-lg leading-7 font-normal text-rgrey hover:text-black">
            <Translate component="header" keyword="stores" />
          </div>
        </LocalizedLink>
        <div className="text-lg leading-7 font-bold">Teenustööde hinnakiri</div>
      </div> */}
      <h4 className="text-4xl leading-10 font-bold my-5 mx-auto text-center md:text-start">
        Teenustööde hinnakiri
      </h4>
      <div className="flex gap-2 items-center">
        <p className="text-lg leading-7 font-normal">
          Rademarist ostetud lisavarustuse paigaldamine
        </p>
        <p className="text-lg leading-7 font-normal text-rgreen uppercase">
          <Translate component="checkout" keyword="rademar-free" />
        </p>
      </div>
      <div className="mx-auto divide-y divide-gray-900/10">
        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
          <Disclosure as="div">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-xl leading-7 font-axi-bold">
                      <Translate component="bike" keyword="light-maintenance" />
                    </span>
                    <span className="flex h-7 items-center">
                      {open ? (
                        <ChevronUpIcon
                          className="h-10 w-10"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className="h-10 w-10"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 md:pr-12">
                  <div className="flex justify-between md:mx-10 ml-5 border-rgrey-light">
                    <ul className="list-disc list-inside mt-2">
                      <li className="text-base leading-loose font-normal">
                        lõtkude kontroll ja reguleerimine (ei sisalda
                        lahtivõtmist ja vahetust)
                      </li>
                      <li className="text-base leading-loose font-normal">
                        pidurite reguleerimine, kõride õlitamine
                      </li>
                      <li className="text-base leading-loose font-normal">
                        käikude reguleerimine, kõride õlitamine
                      </li>
                      <li className="text-base leading-loose font-normal">
                        vajadusel keti, kasseti, piduriklotside vahetus
                      </li>
                      <li className="text-base leading-loose font-normal">
                        jooksude rihtimine
                      </li>
                      <li className="text-base leading-loose font-normal">
                        kummide pumpamine
                      </li>
                      <li className="text-base leading-loose font-normal">
                        keti õlitamine
                      </li>
                    </ul>
                    <p className="text-xl leading-7 font-axi-bold mt-2 whitespace-nowrap hidden md:block">
                      Hind: 40 €
                    </p>
                    <p className="text-xl leading-7 font-axi-bold mt-2 whitespace-nowrap md:hidden">
                      40 €
                    </p>
                  </div>
                  <p className="text-base leading-6 font-normal bg-rgrey-lightlight flex items-center justify-center py-3 px-3 m-5 rounded md:rounded-none text-center">
                    Tööde hinnale lisandub jalgratta hoiustamise tasu 3€ päevas
                    alates 5st päevast.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </dl>
        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
          <Disclosure as="div" className="pt-6">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-xl leading-7 font-axi-bold">
                      <Translate component="bike" keyword="full-maintenance" />
                    </span>
                    <span className="flex h-7 items-center">
                      {open ? (
                        <ChevronUpIcon
                          className="h-10 w-10"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className="h-10 w-10"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 md:pr-12">
                  <div className="flex justify-between md:mx-10 ml-5 border-rgrey-light">
                    <ul className="list-disc list-inside mt-2">
                      <li className="text-base leading-loose font-normal">
                        lõtkude kontroll ja reguleerimine (ei sisalda
                        lahtivõtmist ja vahetust)
                      </li>
                      <li className="text-base leading-loose font-normal">
                        pidurite reguleerimine, kõride õlitamine
                      </li>
                      <li className="text-base leading-loose font-normal">
                        käikude reguleerimine, kõride õlitamine
                      </li>
                      <li className="text-base leading-loose font-normal">
                        vajadusel keti, kasseti, piduriklotside vahetus
                      </li>
                      <li className="text-base leading-loose font-normal">
                        jooksude rihtimine
                      </li>
                      <li className="text-base leading-loose font-normal">
                        kummide pumpamine
                      </li>
                      <li className="text-base leading-loose font-normal">
                        keti õlitamine
                      </li>
                    </ul>
                    <p className="text-xl leading-7 font-axi-bold mt-2 whitespace-nowrap hidden md:block">
                      Hind: 40 €
                    </p>
                    <p className="text-xl leading-7 font-axi-bold mt-2 whitespace-nowrap md:hidden">
                      40 €
                    </p>
                  </div>
                  <p className="text-base leading-6 font-normal bg-rgrey-lightlight flex items-center justify-center py-3 px-3 m-5 rounded md:rounded-none text-center">
                    Tööde hinnale lisandub jalgratta hoiustamise tasu 3€ päevas
                    alates 5st päevast.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </dl>
        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
          <Disclosure as="div" className="pt-6">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-xl leading-7 font-axi-bold">
                      <Translate component="bike" keyword="installation" />
                    </span>
                    <span className="flex h-7 items-center">
                      {open ? (
                        <ChevronUpIcon
                          className="h-10 w-10"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className="h-10 w-10"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 md:pr-12">
                  <div className=" md:mx-10 ml-5">
                    <ul className="list-disc list-inside">
                      <div className="flex justify-between">
                        <li className="text-base leading-loose font-normal">
                          spidomeetri paigaldamine ja seadistamine
                        </li>
                        <p className="text-xl leading-7 font-axi-bold whitespace-nowrap">
                          10 €
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <li className="text-base leading-loose font-normal">
                          sarvede paigaldamine
                        </li>
                        <p className="text-xl leading-7 font-axi-bold whitespace-nowrap">
                          10 €
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <li className="text-base leading-loose font-normal">
                          pakiraami paigaldamine
                        </li>
                        <p className="text-xl leading-7 font-axi-bold whitespace-nowrap">
                          10 €
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <li className="text-base leading-loose font-normal">
                          porilaudade paigaldamine
                        </li>
                        <p className="text-xl leading-7 font-axi-bold whitespace-nowrap">
                          10 €
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <li className="text-base leading-loose font-normal">
                          lastetooli paigaldamine
                        </li>
                        <p className="text-xl leading-7 font-axi-bold whitespace-nowrap">
                          10 €
                        </p>
                      </div>
                    </ul>
                  </div>
                  <p className="text-base leading-6 font-normal bg-rgrey-lightlight flex items-center justify-center py-3 px-3 m-5 rounded md:rounded-none text-center">
                    Tööde hinnale lisandub jalgratta hoiustamise tasu 3€ päevas
                    alates 5st päevast.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </dl>
        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
          <Disclosure as="div" className="pt-6">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-xl leading-7 font-axi-bold">
                      <Translate component="bike" keyword="repair" />
                    </span>
                    <span className="flex h-7 items-center">
                      {open ? (
                        <ChevronUpIcon
                          className="h-10 w-10"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className="h-10 w-10"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 md:pr-12">
                  <div className=" md:mx-10 ml-5">
                    <ul className="list-disc list-inside">
                      <div className="flex justify-between">
                        <li className="text-base leading-loose font-normal">
                          spidomeetri paigaldamine ja seadistamine
                        </li>
                        <p className="text-xl leading-7 font-axi-bold whitespace-nowrap">
                          10 €
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <li className="text-base leading-loose font-normal">
                          sarvede paigaldamine
                        </li>
                        <p className="text-xl leading-7 font-axi-bold whitespace-nowrap">
                          10 €
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <li className="text-base leading-loose font-normal">
                          pakiraami paigaldamine
                        </li>
                        <p className="text-xl leading-7 font-axi-bold whitespace-nowrap">
                          10 €
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <li className="text-base leading-loose font-normal">
                          porilaudade paigaldamine
                        </li>
                        <p className="text-xl leading-7 font-axi-bold whitespace-nowrap">
                          10 €
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <li className="text-base leading-loose font-normal">
                          lastetooli paigaldamine
                        </li>
                        <p className="text-xl leading-7 font-axi-bold whitespace-nowrap">
                          10 €
                        </p>
                      </div>
                    </ul>
                  </div>
                  <p className="text-base leading-6 font-normal bg-rgrey-lightlight flex items-center justify-center py-3 px-3 m-5 rounded md:rounded-none text-center">
                    Tööde hinnale lisandub jalgratta hoiustamise tasu 3€ päevas
                    alates 5st päevast.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </dl>
      </div>
      <div className="md:mt-20 mt-5 md:block hidden">
        <h4 className="text-4xl leading-10 font-bold my-5">
          Lorem ipsum dolor sit.
        </h4>
        <p className="text-lg leading-7 font-normal">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit.
        </p>

        <div className="grid md:grid-cols-4 gap-4 mt-5 items-center">
          <p className="text-8xl leading-none font-axi-bold text-center">20</p>
          <p className="text-8xl leading-none font-axi-bold text-center">3</p>
          <p className="text-8xl leading-none font-axi-bold text-center">15</p>
          <p className="text-5xl leading-none font-axi-bold text-center">
            2000+
          </p>
        </div>
        <div className="grid md:grid-cols-4 gap-4 mt-2">
          <p className="text-base leading-6 font-normal text-center">
            Usaldatud mehhaanikut üle Eesti
          </p>
          <p className="text-base leading-6 font-normal text-center">
            Eelbroneeides ratas valmis 3h
          </p>
          <p className="text-base leading-6 font-normal text-center">
            Aastat kogemust
          </p>
          <p className="text-base leading-6 font-normal text-center">
            Komplekteeritud ja hooldatud ratast
          </p>
        </div>
      </div>
    </div>
  );
};
export default Catalog;
