import React, { useState, useEffect } from 'react';
import ModalContent from './ModalContent';

interface Props {
  buttonClass: string;
  button: React.ReactNode;
  content: React.ReactNode;
  contentTitle: React.ReactNode;
  modalClass: string;
  icon?: React.ReactNode;
}

const Modal = ({
  buttonClass,
  button,
  content,
  contentTitle,
  modalClass,
  icon,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    return () => {
      document.querySelector('body').classList.remove('scroll-lock');
    };
  }, []);

  const openModal = () => {
    setModalOpen(true);
    document.querySelector('body').classList.add('scroll-lock');
  };

  const closeModal = () => {
    setModalOpen(false);
    document.querySelector('body').classList.remove('scroll-lock');
  };

  return (
    <>
      <div className={buttonClass} onClick={openModal}>
        {icon}
        {button}
      </div>
      {modalOpen && (
        <ModalContent
          content={content}
          contentTitle={contentTitle}
          closeModal={closeModal}
          modalClass={modalClass}
        />
      )}
    </>
  );
};

export default Modal;
