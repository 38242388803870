import React from 'react';
import { useNavigate } from 'react-router-dom';
import Login from './Login';

const LoginIndex = ({ toggle }: { toggle: () => void }) => {
  const navigate = useNavigate();
  return <Login navigate={navigate} toggle={toggle} />;
};

export default LoginIndex;
