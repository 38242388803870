export const getItem = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (err) {
    // Localstorage is not supported in some rare cases.
    // Fallback to having no memory.
    return undefined;
  }
};

export const setItem = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    // Ignore error
  }
};
