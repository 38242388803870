import { isLvDomain } from '../services/Env';
import { getItem, setItem } from '../services/Memory';

import Ajax from '../services/Ajax';

export enum Language {
  ET = 'et',
  RU = 'ru',
  EN = 'en',
  LV = 'lv',
}

export const LANG_LOCAL_STORAGE_KEY = 'rademar-language';
export const LANG_URL_PARAM = 'lang';

export default class Translations {
  language = Language.ET;

  constructor() {
    // Attach lang parameter to all api requests
    Ajax.interceptors.request.use((config) => {
      config.params = config.params || {};
      if (!config.params[LANG_URL_PARAM]) {
        config.params[LANG_URL_PARAM] = this.language;
      }
      return config;
    });
  }

  public isValidLanguage(localeStr: string) {
    if (!localeStr) {
      return false;
    }

    return !!Language[localeStr.toUpperCase()];
  }

  public isDefaultLanguage(localeStr: string) {
    if (isLvDomain()) {
      return localeStr === Language.LV;
    }

    return localeStr === Language.ET;
  }

  setLanguage(locale) {
    setItem(LANG_LOCAL_STORAGE_KEY, locale);
    this.language = locale;
  }

  getStoredLanguage() {
    return getItem(LANG_LOCAL_STORAGE_KEY);
  }

  getLanguage() {
    return this.language;
  }

  getAvailableLanguages() {
    return Object.keys(Language).map((lang) => Language[lang]);
  }
}
