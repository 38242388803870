import { setItem, getItem } from './Memory';
import { isDev } from './Env';
import Ajax from './Ajax';

const PERSONALISATION_KEY = 'rademar-cart-token';

export const setPersonalisationKey = (key: string) => {
  setItem(PERSONALISATION_KEY, key);

  if (key) {
    Ajax.defaults.headers.common['Rademar-Personalisation'] = key;
  }
};

export const getPersonalisationKey = () => {
  const key = getItem(PERSONALISATION_KEY);

  // Add dev suffix to personalisation key to avoid mixing
  // production environment data with dev.
  if (key && isDev()) {
    return `${key}-dev`;
  }

  return key;
};
