import { observable, action, makeObservable } from 'mobx';

import Ajax from '../services/Ajax';
import Sorter from '../models/Sorter';

export default class Sorters {
  @observable available = [];
  @observable default: Sorter;

  constructor() {
    makeObservable(this);
  }

  async loadSorters(orderBy, order) {
    if (this.available.length) {
      return this.available;
    }

    const { data: available } = await Ajax.get('sorters');
    this.setSorters(available);

    const activeSorter = available.find(
      (a) => a.order === order && a.orderby === orderBy,
    );

    if (activeSorter) {
      this.changeSorter(activeSorter.id);
    } else {
      this.changeSorter(available[0].id);
    }

    return this.available;
  }

  async loadDefault() {
    await this.loadSorters('', '');

    return this.default;
  }

  @action setSorters(sorters) {
    this.available = sorters;
  }

  @action changeSorter(sorterId) {
    this.default = this.available.find((sorter) => sorter.id === sorterId);
  }
}
