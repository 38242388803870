import { observable, action, makeObservable } from 'mobx';

import Ajax from '../services/Ajax';

export default class Shipping {
  @observable methods = [];

  constructor() {
    makeObservable(this);
  }

  async loadShippingMethods() {
    if (this.methods.length) {
      return this.methods;
    }

    const { data: methods } = await Ajax.get('/shipping');
    this.setMethods(methods);

    return this.methods;
  }

  @action setMethods(methods) {
    this.methods = methods;
  }

  hasOptions(methodSlug: string) {
    const method = this.methods.find((m) => m.slug === methodSlug);
    return !!(method && method.has_options);
  }

  async loadOptions(methodSlug: string) {
    let options = [];

    if (this.hasOptions(methodSlug)) {
      const { data: shippingOptions } = await Ajax.get(
        `/shipping/${methodSlug}`,
      );
      options = shippingOptions;
    }

    return options;
  }
}
