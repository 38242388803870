/* eslint-disable react/prop-types */
// TODO: Have not figured out how to get proper type definitions for Algolia

import React from 'react';
import LocalizedLink from '../LocalizedLink';
import Product from '../../models/Product';
import Price from '../Price/Price';
import DiscountPercentage from '../Price/DiscountPercentage';

const SearchHit = ({ hit, close, sendEvent }) => {
  const product = new Product(hit);

  const handleClick = () => {
    sendEvent('click', hit, 'Hit Clicked');
    close();
  };

  return (
    <LocalizedLink
      to={`/toode/${product.slug}`}
      className="p-2 block"
      onClick={handleClick}
    >
      <div className="relative">
        <DiscountPercentage
          prices={product.prices(true)}
          componentClassname="absolute left-0 !text-xs"
          containerClassname="items-start pt-2.5 pl-2.5 md:pt-4 md:pl-4"
        />
        <img className="w-full" src={hit.image} alt={hit.title} />
        <h1 className="text-sm p-2">{hit.title}</h1>
        <div className="px-2">
          <Price
            prices={product.prices(true)}
            discountPriceClassname="text-black mr-2"
          />
        </div>
      </div>
    </LocalizedLink>
  );
};

export default SearchHit;
