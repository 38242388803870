import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

const usePageTracking = () => {
  const location = useLocation();
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    sendDataToGTM({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;
