import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action, when, makeObservable } from 'mobx';
import CartStore from '../../stores/Cart';

import Heart from '../../images/heart.svg';
import FullHeart from '../../images/heart_full.svg';

import classNames from 'classnames';

interface Props {
  cart?: CartStore;
  productSlug: string;
  productNew?: boolean;
  type?: string;
  componentClassname?: string;
}

@inject('cart')
@observer
export default class FavouriteIcon extends React.Component<Props> {
  @observable favourite = false;
  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.onFavouriteClick = this.onFavouriteClick.bind(this);
  }

  componentDidMount() {
    const { cart } = this.props;

    when(
      () => cart.initialized,
      () => this.checkFavourite(),
    );
  }

  checkFavourite() {
    const { cart, productSlug } = this.props;

    const found = cart.favourites.some(
      (product) => product.slug === productSlug,
    );
    this.setFavourite(found);
  }

  @action setFavourite(found: boolean) {
    this.favourite = found;
  }

  async onFavouriteClick() {
    const { cart, productSlug } = this.props;
    await cart.toggleFavourite(productSlug);
    this.checkFavourite();
  }

  public render() {
    const { type, componentClassname } = this.props;

    let icon = (
      <img
        className="m-2 w-4 h-4"
        src={this.favourite ? FullHeart : Heart}
        onClick={this.onFavouriteClick}
        alt="Lisa lemmik"
      />
    );

    if (type === 'button') {
      icon = (
        <div onClick={this.onFavouriteClick}>
          <img
            className="m-2 w-6 h-6"
            src={this.favourite ? FullHeart : Heart}
            alt="Lisa lemmik"
          />
        </div>
      );
    }

    return (
      <div
        className={`flex items-center cursor-pointer ${classNames(
          componentClassname,
        )}`}
      >
        {icon}
      </div>
    );
  }
}
