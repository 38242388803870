import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Banner } from './SingleBanner';
import BannerDesign from './BannerDesign';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

type Props = {
  banners: [Banner];
};

const BannerCarousel = ({ banners }: Props) => {
  return (
    <Carousel
      dynamicHeight={false}
      autoPlay={true}
      transitionTime={1000}
      infiniteLoop={true}
      interval={7000}
      showThumbs={false}
      stopOnHover={true}
    >
      {banners.map((banner) => (
        <BannerDesign banner={banner} key={banner.slug} />
      ))}
    </Carousel>
  );
};

export default BannerCarousel;
