export enum Sizes {
  W100 = '100',
  W300 = '300',
  W356 = '356',
  W435 = '435',
  W540 = '540',
  W800 = '800',
  W890 = '890',
  W1000 = '1000',
  W1600 = '1600',
}
