import Ajax from '../services/Ajax';

export default class Blocks {
  async loadBlock(type: string) {
    const { data: block } = await Ajax.get(`blocks/${type}`);
    return block;
  }

  async loadProducts(type: string, slug: string) {
    const { data: products } = await Ajax.get(`blocks/${type}/products`, {
      params: {
        category: slug,
      },
    });
    return products;
  }
}
