import React from 'react';
import { inject, observer } from 'mobx-react';

import Translate from '../../Translate/Translate';

import './styles/index.scss';

interface Props {
  className?: string;
  type: string;
  name: string;
  keyword: string;
  store;
}

@inject('store')
@observer
export default class Input extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
  }

  handleValueChange(event) {
    const { store } = this.props;
    store.setValue(event.target.name, event.target.value);
  }

  validateInput(event) {
    return event;
  }

  render() {
    const { className, type, name, keyword, store } = this.props;

    return (
      <div className={`legacy-form-input ${className}`}>
        <label htmlFor={name}>
          <Translate component="login" keyword={keyword} />
        </label>
        <input
          type={type}
          name={name}
          id={name}
          onChange={this.handleValueChange}
          onBlur={this.validateInput}
          value={store[name]}
        />
      </div>
    );
  }
}
