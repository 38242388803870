import React from 'react';
import './styles/index.scss';
import Translate from '../Translate/Translate';
import LocalizedLink from '../LocalizedLink';
import { scrollToTop } from '../../services/Util';
import { translations } from '../../stores';
import { Language } from '../../stores/Translations';

const Social = () => {
  const scrollBackToTop = () => {
    scrollToTop();
  };

  const currentLanguage = translations.language;

  return (
    <div className="component__social">
      {currentLanguage === Language.RU ? null : (
        <LocalizedLink onClick={scrollBackToTop} to="/blogi">
          <Translate component="footer" keyword="blogi-rademar" />
        </LocalizedLink>
      )}
      <a
        href="https://www.facebook.com/rademar.ee"
        target="_blank"
        rel="noreferrer"
      >
        Facebook
      </a>
      <a
        href="https://www.instagram.com/rademar.ee/"
        target="_blank"
        rel="noreferrer"
      >
        Instagram
      </a>
    </div>
  );
};

export default Social;
