import React from 'react';
import { gql, useQuery } from '@apollo/client';
import smallPlaceholder from '../../images/homeSmallBannerPlaceholder.svg';
import largePlaceholder from '../../images/homeLargeBannerPlaceholder.svg';
import BannerDesign from './BannerDesign';
import BannerCarousel from './BannerCarousel';

const GET_BANNER = gql`
  query Banners($slot: String) {
    banners(slot: $slot) {
      slug
      bannerType
      image {
        urls {
          W435
          W540
          W890
          W1600
        }
      }
      buttons {
        buttonType
        title
        url
        buttonGroup
        brand {
          logoUrl
        }
      }
    }
  }
`;

export type Banner = {
  slug: string;
  bannerType: string;
  buttons: {
    buttonType: string;
    title: string;
    url: string;
    buttonGroup: string;
    brand: {
      logoUrl: string;
    };
  }[];
  image: {
    urls: {
      W435: string;
      W540: string;
      W890: string;
      W1600: string;
    };
  };
};

type Props = {
  slot: string;
  size: string;
};

const SingleBanner = ({ slot, size }: Props) => {
  const { data, loading, error } = useQuery(GET_BANNER, {
    variables: { slot },
  });

  if (loading || error || !data.banners?.length) {
    if (size === 'small') {
      return (
        <img
          src={smallPlaceholder}
          width="435"
          height="717"
          className="rounded-lg w-full h-full object-cover"
          alt="Rademar"
        />
      );
    } else {
      return (
        <img
          src={largePlaceholder}
          width="890"
          height="718"
          className="rounded-lg w-full h-full object-cover"
          alt="Rademar"
        />
      );
    }
  }

  if (data.banners.length > 1) {
    return <BannerCarousel banners={data.banners} />;
  }

  return <BannerDesign banner={data.banners[0]} />;
};

export default SingleBanner;
