const SIZE_MAPPING = {
  '6XL': 1000,
  '5XL': 995,
  '4XL': 990,
  '3XL': 985,
  '2XL': 980,
  XXL: 975,
  XL: 970,
  'L/L': 965,
  'L/S': 960,
  L: 955,
  'M/L': 950,
  'M/S': 945,
  M: 940,
  'S/L': 935,
  'S/S': 930,
  S: 925,
  'XS/S': 920,
  XS: 915,
  '2XS': 910,
  '3X': 905,
  '2X': 900,
  '1X': 895,
  '158/170': 850,
  '158/164': 845,
  '156/166': 840,
  '147/158': 835,
  '146/156': 830,
  '146/152': 825,
  '137/147': 820,
  '137/146': 815,
  '137/145': 810,
  '134/146': 805,
  '134/140': 800,
  '128/137': 795,
  '122/128': 790,
  '40/32': 750,
  '38/32': 745,
  '36/32': 740,
  '34/32': 735,
  '32/32': 730,
  '30/32': 725,
  '61cm': 700,
  '60cm': 695,
  '58cm': 690,
  '56cm': 685,
  '54cm': 680,
  '53cm': 675,
  '49 1/3': 49.3,
  '48 2/3': 48.6,
  '47 1/3': 47.3,
  '46 2/3': 46.6,
  '45 1/3': 45.3,
  '44 2/3': 44.6,
  '44 1/2': 44.5,
  '44 1/3': 44.3,
  '43 1/3': 43.3,
  '42 2/3': 42.6,
  '41 1/3': 41.3,
  '40 2/3': 40.6,
  '40 1/2': 40.5,
  '39 1/3': 39.3,
  '38 2/3': 38.6,
  '37 1/3': 37.3,
  '36 2/3': 36.6,
  '35 1/2': 35.5,
  '35 1/3': 35.3,
  '49 ⅓': 49.3,
  '48 ⅔': 48.6,
  '47 ⅓': 47.3,
  '46 ⅔': 46.6,
  '45 ⅓': 45.3,
  '44 ⅔': 44.6,
  '44 ½': 44.5,
  '44 ⅓': 44.3,
  '43 ⅓': 43.3,
  '42 ⅔': 42.6,
  '41 ⅓': 41.3,
  '40 ⅔': 40.6,
  '40 ½': 40.5,
  '39 ⅓': 39.3,
  '38 ⅔': 38.6,
  '37 ⅓': 37.3,
  '36 ⅔': 36.6,
  '35 ½': 35.5,
  '35 ⅓': 35.3,
  '24"': 450,
  '23"': 445,
  '22.5"': 440,
  '21.5"': 435,
  '21"': 430,
  '20"': 425,
  '19.5"': 420,
  '19"': 415,
  '18.5"': 410,
  '18"': 405,
  '17.5"': 400,
  '17"': 395,
  '16"': 390,
  '15.5"': 385,
  '15"': 380,
  '13.5"': 375,
  '13"': 370,
};

function getSizeValue(value) {
  return SIZE_MAPPING[value] || 0;
}

export default class Product {
  data = null;

  constructor(data) {
    this.data = Object.assign({}, data);
  }

  get id() {
    return this.data.id;
  }

  get title() {
    return this.data.title;
  }

  get slug() {
    return this.data.slug;
  }

  get season_id() {
    return this.data.season_id;
  }
  get isNew() {
    return this.data.isNew;
  }

  get cover() {
    if (this.data.cover) {
      return this.data.cover;
    }

    if (this.data.image) {
      return {
        urls: {
          W540: this.data.image,
          W300: this.data.image_w300,
          W800: this.data.image_w800,
        },
      };
    }
  }

  get images() {
    return this.data.media ? this.data.media : [];
  }

  get descriptionText() {
    return this.data.description ? this.data.description.description : '';
  }

  get description() {
    return this.data.description ? this.data.description : '';
  }

  get meta_description() {
    if (this.data.description) {
      if (this.data.description.meta_description) {
        return this.data.description.meta_description.replace(/<[^>]*>?/gm, '');
      }
    }

    return 'Jalatsid, vabaaja- ja spordiriided, rattad ning aksessuaarid naistele, meestele ja lastele. Üle 4500 toote brändidelt nagu Nike, adidas, Puma, Trek, Kross, Didriksons, Columbia, Icepeak ja EA7.';
  }

  get specifications() {
    if (this.data.description) {
      return this.data.description.specifications;
    }

    return '';
  }

  get introduction() {
    if (this.data.description) {
      return this.data.description.introduction;
    }

    return '';
  }

  get technologies() {
    return this.data.technologies;
  }

  get brandTitle() {
    if (this.data.brand) {
      return this.data.brand;
    }

    if (this.data.brands?.length) {
      return this.data.brands[0].title;
    }

    return '';
  }

  get categoryTitle() {
    if (this.data.categories?.length) {
      return this.data.categories[0].title;
    }
  }

  get gridTitle() {
    if (this.data.gridTitle) {
      return this.data.gridTitle;
    }

    if (this.data.categories?.length) {
      return (
        this.data.categories[0].grid_title || this.data.categories[0].gridTitle
      );
    }
  }

  get longTitle() {
    if (this.data.categories?.length) {
      return this.data.categories[0].long_title;
    }
  }

  get productTitle() {
    if (this.data.categories?.length) {
      return this.data.categories[0].product_title;
    }
  }

  fullTitle() {
    return `${this.brandTitle} ${this.title}`;
  }

  isBike() {
    if (this.data.categories?.length) {
      if (
        this.data.categories[0].id >= 145 &&
        this.data.categories[0].id <= 156
      ) {
        return true;
      }
    }
    return false;
  }

  get defaultPrice() {
    if (!this.data.prices) {
      return;
    }

    if (this.data.prices.length) {
      return this.data.prices.find((price) => price.type === 'default').value;
    }
  }

  prices(client = false, cart = false) {
    if (!this.data.prices) {
      return [];
    }

    if (!client && cart) {
      return this.data.prices.filter(
        (price) => price.type !== 'client_extra' && price.type !== 'client',
      );
    }
    if (!client) {
      return this.data.prices.filter((price) => price.type !== 'client_extra');
    }

    return this.data.prices;
  }

  finalPrice(client = false, quantity = 1) {
    const prices = this.prices(client, true);
    return (
      prices.reduce(
        (min, price) => (price.value < min ? price.value : min),
        prices[0].value,
      ) * quantity
    );
  }

  discountPercentage(client = false, quantity = 1) {
    const prices = this.prices(client, true);

    const minPrice: number =
      prices.reduce(
        (min, price) => (price.value < min ? price.value : min),
        prices[0].value,
      ) * quantity;
    const maxPrice: number =
      prices.reduce(
        (max, price) => (price.value > max ? price.value : max),
        prices[0].value,
      ) * quantity;

    const discount: number = ((minPrice - maxPrice) / maxPrice) * 100;

    if (discount < 0) {
      return `${discount.toFixed()}%`;
    }
    return '';
  }

  get categories() {
    return this.data.categories;
  }

  get sizeVariations() {
    const variations = this.data.variations.filter(
      (variation) => variation.type === 'size',
    );

    variations.sort((a, b) => {
      const aUnparsed = a.value.replace(',', '.');
      const bUnparsed = b.value.replace(',', '.');
      const aSize = isNaN(aUnparsed) ? getSizeValue(aUnparsed) : +aUnparsed;
      const bSize = isNaN(bUnparsed) ? getSizeValue(bUnparsed) : +bUnparsed;

      if (aSize < bSize) {
        return -1;
      } else if (aSize > bSize) {
        return 1;
      }

      return 0;
    });

    return variations;
  }
}
