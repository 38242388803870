import React, { useEffect, useState } from 'react';
import { Sizes } from '../../constants/Image';
import imgPlaceholder from '../../images/imgPlaceholder.svg';

export type ImageData = {
  id?: number;
  urls: { [key: string]: string };
};

interface Props {
  className?: string;
  defaultSize: Sizes;
  image: ImageData;
  allSizes?: Sizes[];
  sizes?: string[];
  width?: string;
  height?: string;
  alt?: string;
}

const generateSrcSet = (image, allSizes: Sizes[]) => {
  let srcSetImages = [];

  if (image.urls) {
    srcSetImages = allSizes
      .map((size) => {
        if (image.urls['W' + size]) {
          return `${image.urls['W' + size]} ${size}w`;
        }
        return null;
      })
      .filter((size) => size !== null);
  }

  return srcSetImages;
};

const Image: React.FC<Props> = ({
  image,
  defaultSize,
  className = '',
  allSizes = [],
  sizes = [],
  width,
  height,
  alt,
}) => {
  const [currentSrcSet, setCurrentSrcSet] = useState([]);
  const [loadFailed, setLoadFailed] = useState(false);

  useEffect(() => {
    if (image) {
      const newSrcSet = generateSrcSet(image, allSizes);
      setCurrentSrcSet(newSrcSet);
    }
  }, [image]);

  if (image) {
    let src = undefined;

    if (image.urls && image.urls['W' + defaultSize]) {
      src = image.urls['W' + defaultSize];
    }

    return (
      <img
        className={className}
        src={loadFailed ? imgPlaceholder : src}
        srcSet={loadFailed ? undefined : currentSrcSet.join()}
        sizes={sizes.join()}
        width={width}
        height={height}
        alt={alt}
        onError={() => setLoadFailed(true)}
      />
    );
  }

  return (
    <img
      className={'rounded bg-rgrey-lightlight object-cover h-full w-full'}
      src={imgPlaceholder}
      alt={alt}
    />
  );
};

export default Image;
