import Ajax from '../services/Ajax';
import { translations } from '.';

export default class Payment {
  getReturnUrl() {
    const language = translations.getLanguage();
    let returnUrl = window.location.origin;
    if (language === 'ru') {
      returnUrl += '/ru';
    }
    return returnUrl;
  }

  async transaction(reference: string, method: string) {
    const { data } = await Ajax.post(`payment/transaction/${reference}`, {
      payment_method: method,
      return_url: this.getReturnUrl(),
    });
    return data;
  }

  async montonioTransaction(reference: string, methodCode: string) {
    const { data } = await Ajax.post(`v2/payment/transaction/${reference}`, {
      paymentMethod: methodCode,
      returnUrl: this.getReturnUrl(),
    });
    return data;
  }

  async inbank(reference: string, methodName: string) {
    const { data } = await Ajax.post(
      `payment/inbank/${methodName}/order/${reference}`,
      {
        return_url: this.getReturnUrl(),
      },
    );
    return data;
  }

  async modena(reference: string) {
    const { data } = await Ajax.post(`payment/modena/order/${reference}`, {
      returnUrl: this.getReturnUrl(),
    });
    return data;
  }
}
