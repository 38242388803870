import React from 'react';
import useStores from '../../hooks/useStores';
import './styles/QuantityButton.scss';

interface Props {
  data: {
    quantity: number;
  };
}

const QuantityButton = ({ data }: Props) => {
  const { cart, checkout } = useStores();

  const removeProduct = async (variation, quantity) => {
    await cart.removeItem(variation.code, quantity);
    await checkout.loadPaymentMethods();
  };

  const addProduct = async (variation?) => {
    await cart.addItem(variation.code);
    await checkout.loadPaymentMethods();
  };

  return (
    <div className="component-quantityButton">
      <div className="component-quantityButton__container">
        <div
          className="component-quantityButton__remove"
          onClick={() => removeProduct(data, 1)}
        >
          -
        </div>
        {data.quantity >= 1 ? (
          <span className="component-quantityButton__title">
            {data.quantity}
          </span>
        ) : null}
        <div
          className="component-quantityButton__add"
          onClick={() => addProduct(data)}
        >
          +
        </div>
      </div>
    </div>
  );
};

export default QuantityButton;
