import React from 'react';
import { Link } from 'react-router-dom';
import { inject } from 'mobx-react';
import Translations from '../../stores/Translations';

interface Props {
  to: string;
  children: React.ReactNode;
  translations?: Translations;
  className?: string;
  onClick?: () => void;
  label?: string;
  reloadDocument?: boolean;
}

@inject('translations')
export default class LocalizedLink extends React.Component<Props> {
  render() {
    const {
      to,
      children,
      translations,
      className,
      onClick,
      label,
      reloadDocument,
    } = this.props;

    const lang = translations.getLanguage();
    let toLink = to || '';
    if (!translations.isDefaultLanguage(lang)) {
      toLink = `/${lang}${toLink}`;
    }

    return (
      <Link
        to={toLink}
        className={className}
        onClick={onClick}
        aria-label={label}
        reloadDocument={reloadDocument}
      >
        {children}
      </Link>
    );
  }
}
