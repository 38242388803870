import React from 'react';
import HomeBlock from './HomeBlock';
import BlogBlock from './BlogBlock';
import BannersLayout from '../HomeBanners/BannersLayout';
import { translations } from '../../stores';
import { Language } from '../../stores/Translations';

export default class Home extends React.Component {
  public render() {
    const currentLanguage = translations.language;

    return (
      <section className="home">
        <>
          <BannersLayout />
          <HomeBlock />
          {currentLanguage === Language.RU ? null : <BlogBlock limit={2} />}
        </>
      </section>
    );
  }
}
