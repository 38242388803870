import React, { useState, useEffect, useRef } from 'react';
import { Hits, useSearchBox } from 'react-instantsearch';
import { useDebounceValue, useOnClickOutside } from 'usehooks-ts';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchHit from './SearchHit';
import { translate } from '../../services/Translations';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

const SearchInput = () => {
  const navigate = useNavigate();
  const { refine, query } = useSearchBox();
  const [hitsVisible, setHitsVisible] = useState(false);
  const [currentQuery, setCurrentQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useDebounceValue('', 300);

  const hitsRef = useRef(null);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const searchQueryValue = queryParams.get('search');
  const [translatedPlaceholder, setTranslatedPlaceholder] = useState('');

  useEffect(() => {
    setDebouncedQuery(currentQuery);
  }, [currentQuery]);

  useEffect(() => {
    setCurrentQuery(searchQueryValue || '');
  }, [searchQueryValue]);

  useEffect(() => {
    if (debouncedQuery) {
      refine(debouncedQuery);
    }
  }, [debouncedQuery]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentQuery(e.target.value);
    setHitsVisible(!!e.target.value);
  };

  const handleSearchFocus = () => {
    if (currentQuery && !hitsVisible) {
      setHitsVisible(true);
    }
  };

  const handleSearchSubmit = (event: React.FormEvent) => {
    if (currentQuery) {
      navigate({
        pathname: '/tooted',
        search: `?query=${currentQuery}`,
      });
      handleCloseHits();
    }

    event.preventDefault();
  };

  const handleCloseHits = () => {
    setHitsVisible(false);
  };

  const handleClickOutsideHits = () => {
    handleCloseHits();
  };

  useOnClickOutside(hitsRef, handleClickOutsideHits);

  useEffect(() => {
    const translatePlaceholder = async () => {
      const translated = await translate('search', 'search');
      setTranslatedPlaceholder(translated);
    };

    translatePlaceholder();
  }, []);

  return (
    <div>
      <form onSubmit={handleSearchSubmit}>
        <div className="relative">
          <input
            className="w-full h-15 lg:h-auto py-2.5 pl-3 pr-12 text-sm border-0 lg:rounded bg-rgrey-light focus:outline-none focus:ring-0"
            value={currentQuery}
            onChange={handleSearchChange}
            onFocus={handleSearchFocus}
            placeholder={translatedPlaceholder}
          />
          <button
            onClick={handleSearchSubmit}
            className="absolute text-gray-500 -translate-y-1/2 top-1/2 right-4 z-40"
          >
            <MagnifyingGlassIcon className="w-6 h-6 text-black" />
          </button>
        </div>
        <input type="submit" hidden />
      </form>

      {hitsVisible && query && (
        <div
          ref={hitsRef}
          className="absolute left-0 w-full bg-white drop-shadow mt-0 lg:mt-2 p-4 flex max-h-[66vh]"
        >
          <Hits
            hitComponent={(props) => (
              <SearchHit {...props} close={handleCloseHits} />
            )}
            classNames={{
              root: 'max-h-full overflow-y-auto',
              list: 'flex flex-wrap ',
              item: 'w-1/2 sm:w-1/3 md:w-1/4 xl:w-1/5 2xl:w-1/6',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
