import React from 'react';
import { observable, action, makeObservable } from 'mobx';

import Ajax from '../services/Ajax';

export default class JobOffers {
  @observable offers = [];
  @observable loaded = false;
  ref = React.createRef();

  constructor() {
    makeObservable(this);
  }

  public async load() {
    const { data: offers } = await Ajax.get('job_offers');
    this.setOffers(offers);
  }

  @action public setOffers(offers) {
    this.offers = offers;
    this.loaded = true;
  }

  public getBySlug(slug: string) {
    return this.offers.find((p) => p.slug === slug);
  }
}
