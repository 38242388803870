import Ajax from '../services/Ajax';

export default class Recruit {
  async postEmailData(data) {
    const user_data = await Ajax.post('recruit/subscribe', {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone,
    });

    return user_data;
  }
}
