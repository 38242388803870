import React from 'react';
import Translate from '../Translate/Translate';

const MainMenuInfo = () => (
  <div className="text-black bg-rgrey-light text-center box-border mx-auto my-0 px-4 py-2.5 text-xs leading-5 lg:bg-black lg:text-white">
    <div className="lg:flex lg:justify-between lg:max-w-screen-3xl lg:mx-auto lg:px-7">
      <div className="lg:flex">
        <p className="lg:pr-7">
          <Translate component="header" keyword="delivery-info" />
        </p>
        <p>
          <Translate component="header" keyword="return-exchange" />
        </p>
      </div>
      <div className="hidden lg:block">
        <p>
          <Translate component="header" keyword="customer-support" />
          <a href="tel:6154138" className="px-1">
            615 4138
          </a>
          /
          <a href="mailto:epood@rademar.ee" className="px-1">
            epood@rademar.ee
          </a>
        </p>
      </div>
    </div>
  </div>
);

export default MainMenuInfo;
