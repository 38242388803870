import { observable, action, makeObservable } from 'mobx';
import { authTokenName } from '../services/Device';

import Ajax from '../services/Ajax';

export default class Authentication {
  @observable initialized = false;
  @observable loggedIn = false;

  @observable loginEmail = '';
  @observable loginPassword = '';

  @observable registerEmail = '';
  @observable registerPassword = '';
  @observable registerPasswordConfirmation = '';

  @observable resetEmail = '';

  @observable setEmail = '';
  @observable setPassword = '';
  @observable setPasswordConfirmation = '';

  constructor() {
    makeObservable(this);
  }

  @action setValue(name: string, value: string | boolean) {
    this[name] = value;
  }

  @action setLoginStatus(newStatus: boolean) {
    this.loggedIn = newStatus;
    this.loginEmail = '';
    this.loginPassword = '';
    this.initialized = true;
  }

  async authenticate() {
    const authResponse = await Ajax.post('login', {
      email: this.loginEmail,
      password: this.loginPassword,
    });
    this.setLoginStatus(true);
    await this.makeToken(true);
    return authResponse;
  }

  async register() {
    try {
      await Ajax.post('register', {
        email: this.registerEmail,
        password: this.registerPassword,
        password_confirmation: this.registerPasswordConfirmation,
      });
      this.setLoginStatus(true);
      await this.makeToken(true);
    } catch (error) {
      throw error.response.data.errors;
    }
  }

  async logout() {
    localStorage.removeItem(authTokenName());
    await Ajax.post('logout', { device_name: authTokenName() });
    this.setLoginStatus(false);
  }

  async check() {
    const { data } = await Ajax.get('authentication/check');

    if (data === 'ok') {
      this.setLoginStatus(true);
      await this.makeToken();
    } else {
      this.setLoginStatus(false);
    }

    return data;
  }

  async makeToken(force = false) {
    // Check if user has authentication token also for GraphQL requests.
    const token = localStorage.getItem(authTokenName());

    if (!token || force) {
      const tokenResponse = await Ajax.post('login/token', {
        device_name: authTokenName(),
      });
      if (tokenResponse.data) {
        localStorage.setItem(authTokenName(), tokenResponse.data.token);
      }
    }
  }

  async sendResetEmail() {
    const { data } = await Ajax.post('/authentication/reset', {
      email: this.resetEmail,
      redirect: window.location.origin + '/set-password',
    });

    return data;
  }

  async resetPassword(token) {
    try {
      const { data } = await Ajax.post('/authentication/reset_password', {
        token,
        email: this.setEmail,
        password: this.setPassword,
        password_confirmation: this.setPasswordConfirmation,
      });

      return data;
    } catch (error) {
      throw error.response;
    }
  }
}
