import React, { useState } from 'react';
import dayjs from 'dayjs';

type Props = {
  price: number;
};

export default function InBankModal({ price }: Props) {
  const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };
  return (
    <div className="md:min-w-96 m-5">
      <p className="mb-2">
        Inbanki saad kasutada mugavalt Rademari e-poes ja kauplustes.
      </p>
      <p className="font-axi-bold text-xl">Maksa 3 või 6 võrdses osas</p>
      <div className="flex justify-between my-5">
        <p>Periood</p>
        <p className="">Hind</p>
      </div>
      <div className="flex justify-between items-center">
        <div>
          <button
            onClick={() => handleButtonClick(1)}
            className={`border ${activeButton === 1 ? 'border-rgreen rounded bg-rgreen text-white md:w-20 md:h-12' : 'border-gray-200 rounded md:w-20 md:h-12'} p-2 mr-2 my-1`}
          >
            3 kuud
          </button>
          <button
            onClick={() => handleButtonClick(2)}
            className={`border ${activeButton === 2 ? 'border-rgreen rounded bg-rgreen text-white md:w-20 md:h-12' : 'border-gray-200 rounded md:w-20 md:h-12'} p-2 mr-2 my-1`}
          >
            6 kuud
          </button>
        </div>
        <div className="text-rgreen font-axi-bold text-3xl text-nowrap whitespace-nowrap">
          {price} €
        </div>
      </div>
      {activeButton === 1 && (
        <div>
          <table className="md:min-w-96 min-w-64 table-auto leading-loose mt-2">
            <tbody>
              <tr className="border-b border-gray-300">
                <td className="text-wrap">
                  <div>Esimene osamakse</div>
                  <div className="font-axi-bold">
                    {dayjs().add(1, 'month').format('DD.MM.YYYY')}
                  </div>
                </td>
                <td className="text-2xl text-right text-nowrap">
                  {(price / 3).toFixed(2)} €
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td>
                  <div>Teine osamakse</div>
                  <div className="font-axi-bold">
                    {dayjs().add(2, 'month').format('DD.MM.YYYY')}
                  </div>
                </td>
                <td className="text-2xl text-right text-nowrap">
                  {(price / 3).toFixed(2)} €
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td>
                  <div>Kolmas osamakse</div>
                  <div className="font-axi-bold">
                    {dayjs().add(3, 'month').format('DD.MM.YYYY')}
                  </div>
                </td>
                <td className="text-2xl text-right text-nowrap">
                  {(price / 3).toFixed(2)} €
                </td>
              </tr>
            </tbody>
          </table>
          <div className="text-sm max-w-sm leading-5">
            <ul className="list-disc mx-5 mt-4">
              <li>Maksa 3 osas.</li>
              <li>Esimene makse alles kuu aja pärast.</li>
              <li>Ei ühtegi lisatasu.</li>
              <li>Kehtib ostusummale 50 kuni 2500 eur.</li>
              <li>
                <b className="text-axi-bold">NB!</b> Osamaksu kalkulaatori
                kuupäevad ja summad on informatiivse iseloomuga.
              </li>
            </ul>
          </div>
        </div>
      )}
      {activeButton === 2 && (
        <div>
          <table className="md:min-w-96 min-w-64 table-auto leading-loose mt-2">
            <tbody>
              <tr className="border-b border-gray-300">
                <td>
                  <div>Esimene osamakse</div>
                  <div className="font-axi-bold">
                    {dayjs().add(1, 'month').format('DD.MM.YYYY')}
                  </div>
                </td>
                <td className="text-2xl text-right text-nowrap">
                  {(price / 6).toFixed(2)} €
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td>
                  <div>Teine osamakse</div>
                  <div className="font-axi-bold">
                    {dayjs().add(2, 'month').format('DD.MM.YYYY')}
                  </div>
                </td>
                <td className="text-2xl text-right text-nowrap">
                  {(price / 6).toFixed(2)} €
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td>
                  <div>Kolmas osamakse</div>
                  <div className="font-axi-bold">
                    {dayjs().add(3, 'month').format('DD.MM.YYYY')}
                  </div>
                </td>
                <td className="text-2xl text-right text-nowrap">
                  {(price / 6).toFixed(2)} €
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td>
                  <div>Neljas osamakse</div>
                  <div className="font-axi-bold">
                    {dayjs().add(4, 'month').format('DD.MM.YYYY')}
                  </div>
                </td>
                <td className="text-2xl text-right text-nowrap">
                  {(price / 6).toFixed(2)} €
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td>
                  <div>Viies osamakse</div>
                  <div className="font-axi-bold">
                    {dayjs().add(5, 'month').format('DD.MM.YYYY')}
                  </div>
                </td>
                <td className="text-2xl text-right text-nowrap">
                  {(price / 6).toFixed(2)} €
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td>
                  <div>Kuues osamakse</div>
                  <div className="font-axi-bold">
                    {dayjs().add(6, 'month').format('DD.MM.YYYY')}
                  </div>
                </td>
                <td className="text-2xl text-right text-nowrap">
                  {(price / 6).toFixed(2)} €
                </td>
              </tr>
            </tbody>
          </table>
          <div className="text-sm max-w-sm leading-5">
            <ul className="list-disc mx-5 mt-4">
              <li>Maksa 6 osas.</li>
              <li>Esimene makse alles kuu aja pärast.</li>
              <li>Ei ühtegi lisatasu.</li>
              <li>Kehtib ostusummale 50 kuni 2500 eur.</li>
              <li>
                <b className="text-axi-bold">NB!</b> Osamaksu kalkulaatori
                kuupäevad ja summad on informatiivse iseloomuga.
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
