import React from 'react';
import LocalizedLink from '../LocalizedLink';
import Translate from '../Translate/Translate';
import CartStore from '../../stores/Cart';
import { inject, observer } from 'mobx-react';

interface Props {
  cart?: CartStore;
  closeMobileMenu?: () => void;
}

@inject('cart')
@observer
export default class FavouriteButton extends React.Component<Props> {
  render() {
    const { cart, closeMobileMenu } = this.props;
    return (
      <LocalizedLink
        to="/lemmikud"
        onClick={closeMobileMenu}
        className={`flex flex-col items-center border rounded p-4 lg:px-3.5 lg:py-2.5 border-solid border-rgrey-light lg:flex-row-reverse w-full ${
          cart.nrOfFavourites > 0
            ? 'border bg-black text-white border-solid border-black fill-white'
            : ''
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22.914"
          height="20"
          viewBox="0 0 24 20"
        >
          <path
            d="M491.016,765.64l-.747.747-.748-.747a6.275,6.275,0,0,0-8.875,8.874l9.053,9.053a.824.824,0,0,0,.577.234h0a.8.8,0,0,0,.559-.233l9.053-9.054a6.276,6.276,0,0,0-8.876-8.874Zm7.749,7.748-8.5,8.5-8.5-8.5a4.682,4.682,0,0,1,6.621-6.621l1.311,1.312a.827.827,0,0,0,1.131,0l1.307-1.308a4.682,4.682,0,0,1,6.621,6.621Z"
            transform="translate(-478.809 -763.801)"
          />
        </svg>
        <p className="uppercase pt-3 lg:pt-0 lg:pr-4 font-axi-bold text-center lg:whitespace-nowrap">
          <Translate component="mobile-menu" keyword="favourites" />
        </p>
      </LocalizedLink>
    );
  }
}
