import React from 'react';
import { Language } from '../stores/Translations';

const AlgoliaContext = React.createContext({
  appLocale: Language.ET,
  indexName: '',
  queryId: '',
  setQueryId: (newQueryId: string) => {
    return newQueryId;
  },
  setIndexName: (newIndexName: string) => {
    return newIndexName;
  },
});

export default AlgoliaContext;
